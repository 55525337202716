import AvatarWithStatus from "@/components/AvatarWithStatus";
import StatusLight from "@/components/StatusLight";
import Locator from "@/locator";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { whiteColor } from "@/utils";
import { transformWorkspaceMembershipAvailabilityRecord } from "@/utils/transformers";
import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext } from "react";

export default function AliasChannelHeader({
  aliasChannel,
}: {
  aliasChannel: { alias: string; title: string; name: string; id: string };
}) {
  const { accountMap } = useContext(WorkspaceContext);
  const accountRecordWithStatus = accountMap?.get(aliasChannel?.id);

  const transformedRecord = accountRecordWithStatus?.calculatedAvailability
    ? transformWorkspaceMembershipAvailabilityRecord(accountRecordWithStatus)
    : null;

  return (
    <Box
      aria-label={Locator.mainNav.navBar}
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ mr: 2 }}>
          <AvatarWithStatus
            size={40}
            accountId={aliasChannel.id}
            key={aliasChannel.id}
          />
        </Box>
        <Box>
          <Box
            sx={{ fontSize: "18px", mb: "-4px", fontWeight: "bold" }}
            aria-label={Locator.mainNav.displayTitle}
          >
            {aliasChannel.title}
          </Box>
        </Box>
      </Box>

      {transformedRecord?.color && transformedRecord?.status && (
        <Box
          sx={{
            flex: "0 1 auto",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Box>
              <Box
                sx={{
                  mb: "-2px",
                  textAlign: "right",
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Box>{transformedRecord?.status}</Box>
                <StatusLight
                  sx={{ ml: 1 }}
                  color={transformedRecord.color}
                  size={14}
                />
              </Box>
            </Box>
            {transformedRecord?.additionalInfo?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {transformedRecord.additionalInfo.map((ai, aiIndex) => (
                  <Chip
                    key={aiIndex}
                    sx={{
                      color: whiteColor,
                      ml:
                        aiIndex === transformedRecord.additionalInfo.length - 1
                          ? 1
                          : 0,
                    }}
                    color="error"
                    size="small"
                    label={ai}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
