import awsCronParser from "aws-cron-parser";
import * as chrono from "chrono-node";
import { millisecondsToMinutes } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";

const getFeedDateId = (date: string) => `feed-date-${date}`;

export const parseCronScheduledDate = (cronStr: string, timezone: string) => {
  const cron = awsCronParser.parse(cronStr);
  if (cron) {
    const offset = millisecondsToMinutes(getTimezoneOffset(timezone));
    const parsedDate = chrono.parseDate(
      awsCronParser.getScheduleDescription(cron),
      {
        instant: new Date(),
        timezone: offset,
      },
      { forwardDate: true },
    );
    return parsedDate;
  }
  return null;
};

const toMilliseconds = (hrs: number, min: number, sec: number) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;

export type TimeDifference = {
  targetDate: Date;
  now: Date;
  diffInMilliseconds: number;
  diffInMinutes: number;
  diffInHours: number;
  diffInDays: number;
  lastOnline: string;
};

export const dateDiff = (
  timestamp: Date | string,
  minutesThreshold: number,
): TimeDifference => {
  const targetDate = new Date(timestamp);
  const now = new Date();
  const diffInMilliseconds = Math.abs(now.getTime() - targetDate.getTime());
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInMonths / 12);

  let lastOnline = "";
  if (diffInYears > 0) {
    lastOnline = `${diffInYears}y`;
  } else if (diffInMonths > 0) {
    lastOnline = `${diffInMonths}mo`;
  } else if (diffInWeeks > 0) {
    lastOnline = `${diffInWeeks}w`;
  } else if (diffInDays > 0) {
    lastOnline = `${diffInDays}d`;
  } else if (diffInHours > 0) {
    lastOnline = `${diffInHours}h`;
  } else if (diffInMinutes >= minutesThreshold) {
    lastOnline = `${diffInMinutes}m`;
  }

  return {
    targetDate,
    now,
    diffInMilliseconds,
    diffInMinutes,
    diffInHours,
    diffInDays,
    lastOnline,
  };
};
