import AvatarWithStatus from "@/components/AvatarWithStatus";
import NoValidWorkspacesModal from "@/components/NoValidWorkspacesModal";
import Stepper from "@/components/Stepper";
import { FullInput } from "@/components/Utils";
import { client } from "@/config";
import { db } from "@/db/db";
import { account } from "@/db/schema";
import ModalForm from "@/elements/ModalForm";
import Locator from "@/locator";
import { AppContext } from "@/models/AppStateProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { LoadingButton } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { eq } from "drizzle-orm";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Onboarding() {
  const { allowNewWorkspaceCreation } = useFlags();
  const { redirectPath } = useContext(AppContext);
  const { workspaceMemberships, workspaces } = useContext(WorkspaceContext);
  const { myAccount } = useContext(MyAccountContext);
  const { ampli } = React.useContext(TrackingContext);
  const navigate = useNavigate();

  const firstWorkspace = workspaces?.[0]?.id;

  const updateUserInfo = async ({ fullName }) => {
    // @ts-ignore
    await db
      .update(account)
      .set({
        name: fullName,
      })
      .where(eq(account.id, myAccount?.id))
      .execute();
    await client.updateAccount(myAccount?.id, {
      fullName: fullName,
    });
  };

  const defaultStatusState = [{ active: true, step: 1 }];
  const open = true;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [status, setStatus] = useState(defaultStatusState);

  const isFullNameValid = fullName?.replace(/ /g, "")?.length > 0;

  // check if the redirect path has a specified feed we should redirect to
  // i.e. /workspaces/workspaceId/feeds/clkk17h8j00003b70xnvtvhgg
  const shouldRedirect = /[workspaces]+\/(.+)\/[feeds]+\/(.+)/.test(
    redirectPath,
  );

  const currentActiveState = status.filter((item) => item.active).length;

  useEffect(() => {
    if (myAccount?.name) {
      setFullName(myAccount.name);
    }
  }, [myAccount]);

  const theme = useTheme();

  const saveFullName = async () => {
    setError(false);
    if (!fullName || !isFullNameValid) {
      setError(true);
    }
    try {
      setSubmitting(true);
      await updateUserInfo({ fullName });
      ampli.submitFullName();
      return false;
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
      //if we have a url to redirect too then all we need to collect is the name
      if (
        firstWorkspace ||
        (workspaceMemberships?.length > 0 && shouldRedirect)
      ) {
        handleDone();
      } else {
        nextStep();
      }
    }
  };

  const prevStep = () => {
    if (currentActiveState - 1 === 0) {
      return;
    }
    setStatus((oldVal) => {
      oldVal[currentActiveState - 1].active = false;
      return [...oldVal];
    });
  };

  const nextStep = () => {
    if (defaultStatusState.length === currentActiveState) {
      return;
    }
    setStatus((oldVal) => {
      oldVal[currentActiveState].active = true;
      return [...oldVal];
    });
  };

  const handleDone = () => {
    ampli.completeOnboardingDone();
    if (redirectPath && shouldRedirect) {
      window.location.replace(redirectPath);
      return;
    }
    navigate("/workspaces");
  };

  const showSteps = useMemo(
    () => workspaceMemberships?.length === 0 && !shouldRedirect,
    [shouldRedirect, workspaceMemberships],
  );

  if (workspaces?.length === 0 && allowNewWorkspaceCreation === false) {
    return <NoValidWorkspacesModal open={true} />;
  }

  return (
    <ModalForm
      id="onboarding"
      allowClose={false}
      disableClose={true}
      open={open}
      onClose={null}
      closeAfterTransition={true}
      disableEscapeKeyDown={true}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: theme.palette.primary.dark,
            backgroundImage:
              "url(https://storyboard-fm.cdn.prismic.io/storyboard-fm/d3c6f3e4-ffa5-4bb2-9564-5378b56356a0_Login+Background.svg)",
            backgroundPosition: "50%",
            backgroundSize: "cover",
          },
        },
      }}
      sx={{
        border: "none",
        boxShadow: "none",
      }}
      maxHeight={currentActiveState === 4 ? 750 : 600}
    >
      <>
        {showSteps && (
          <Box
            sx={{
              padding: 2,
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
            }}
          >
            <Stepper steps={status} currentActiveStep={currentActiveState} />
          </Box>
        )}
        <Box className="full-button-width" sx={{ width: "100%", mt: 4 }}>
          {currentActiveState === 1 && (
            <Box sx={{ textAlign: "center" }}>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                <AvatarWithStatus size={30} accountId={myAccount?.id} />
              </Box>
              <Typography
                variant="h5"
                component="h2"
                sx={{ color: theme.palette.primary.main, mb: 1 }}
                fontWeight={700}
                aria-label={Locator.onBoarding.modalTitle}
              >
                {Locator.onBoarding.modalTitle}
              </Typography>

              <FullInput
                aria-label={Locator.onBoarding.fullNameInput}
                fullWidth
                error={error}
                helperText="Required *"
                required
                label={Locator.onBoarding.fullNameInput}
                value={fullName}
                callback={(e) => setFullName(e.target.value)}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                aria-label={Locator.onBoarding.submitButton}
                size="large"
                loading={submitting}
                onClick={saveFullName}
                disabled={!isFullNameValid || submitting}
                sx={{
                  width: "100%",
                }}
              >
                {Locator.onBoarding.submitButton}
              </LoadingButton>
            </Box>
          )}
        </Box>
      </>
    </ModalForm>
  );
}
