import { themeOptions } from "@/theme/theme";

const hexToHsl = (hex: string, offsetH = 0, offsetS = 0, offsetL = 0) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let cssString = "";
  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    h = Math.round(h * 360) - offsetH;
    s = Math.round(s * 100) - offsetS;
    l = Math.round(l * 100) - offsetL;

    cssString = `hsl(${h}, ${s}%, ${l}%)`;
  }

  return cssString;
};

// @ts-ignore
export const whiteColor = themeOptions.palette.primary.main;

// @ts-ignore
export const redColor = themeOptions.palette.error.main;

// @ts-ignore
export const grayColor = themeOptions.palette.secondary.main;

// @ts-ignore
export const greenColor = themeOptions.palette.brand.other.green.main;

// @ts-ignore
export const yellowColor = themeOptions.palette.warning.main;
