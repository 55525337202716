import PubSub from "@/PubSub";
import {
  bulkUpsertWsAudioEncodings,
  bulkUpsertWsItems,
  bulkUpsertWsTranscriptions,
  upsertDeviceRegistration,
  upsertDirectWsInvitation,
  upsertWorkspace,
  upsertWorkspaceMembership,
  upsertWsAccount,
  upsertWsBroadcastAction,
  upsertWsBroadcastRecipient,
  upsertWsCommandAlias,
  upsertWsDisplayArtifact,
  upsertWsDraft,
  upsertWsEvent,
  upsertWsFeed,
  upsertWsFeedGroup,
  upsertWsFeedGroupMembership,
  upsertWsFeedPreferences,
  upsertWsFile,
  upsertWsLink,
  upsertWsPAM,
  upsertWsPermission,
  upsertWsPublishedDraft,
  upsertWsScheduleTrigger,
  upsertWsTemplate,
} from "@/data/pg/updates";
import { db } from "@/db/db";
import { workspaceMembership } from "@/db/schema";
import { and, eq } from "drizzle-orm";
import { WsAppSyncEvent } from "web-client/api/data-contracts";
import Client from "web-client/client";
import { initializeUnreadsForAllFeeds } from "./actions/initialFeedLoad";
import { createManyWsMembershipAvailability } from "@/data/pg/bulkInserts";

// Map keyed by "accountId:workspaceId" to store queues of events
type QueueKey = string;
interface EventQueue {
  accountId: string;
  workspaceId: string;
  events: WsAppSyncEvent[];
}

const queues = new Map<QueueKey, EventQueue>();

function getQueueKey(accountId: string, workspaceId: string): QueueKey {
  return `${accountId}:${workspaceId || ""}`;
}

function deduplicateById<T extends { id: string }>(arr: T[]): T[] {
  const map = new Map<string, T>();
  for (const item of arr) {
    map.set(item.id, item);
  }
  return Array.from(map.values());
}
function deDuplicateByworkspaceMembershipId<
  T extends { workspaceMembershipId: string },
>(arr: T[]): T[] {
  const map = new Map<string, T>();
  for (const item of arr) {
    map.set(item.workspaceMembershipId, item);
  }
  return Array.from(map.values());
}

// Function to merge multiple WsAppSyncEvents into a single one
function mergeEvents(events: WsAppSyncEvent[]): WsAppSyncEvent {
  const merged: WsAppSyncEvent = {
    accounts: [],
    workspaces: [],
    workspaceMemberships: [],
    directWorkspaceInvitations: [],
    feeds: [],
    feedGroups: [],
    feedGroupMemberships: [],
    feedPreferences: [],
    permissions: [],
    audioEncodings: [],
    displayArtifacts: [],
    files: [],
    links: [],
    transcriptions: [],
    commandAliases: [],
    workflowItems: [],
    scheduleTriggers: [],
    broadcastActions: [],
    publishedWorkflowItems: [],
    broadcastRecipients: [],
    pam: [],
    templates: [],
    deviceRegistrations: [],
    events: [],
    items: [],
    membershipAvailabilities: [],
  };

  for (const ev of events) {
    if (ev.accounts) merged.accounts.push(...ev.accounts);
    if (ev.workspaces) merged.workspaces.push(...ev.workspaces);
    if (ev.workspaceMemberships)
      merged.workspaceMemberships.push(...ev.workspaceMemberships);
    if (ev.directWorkspaceInvitations)
      merged.directWorkspaceInvitations.push(...ev.directWorkspaceInvitations);
    if (ev.feeds) merged.feeds.push(...ev.feeds);
    if (ev.feedGroups) merged.feedGroups.push(...ev.feedGroups);
    if (ev.feedGroupMemberships)
      merged.feedGroupMemberships.push(...ev.feedGroupMemberships);
    if (ev.permissions) merged.permissions.push(...ev.permissions);
    if (ev.audioEncodings) merged.audioEncodings.push(...ev.audioEncodings);
    if (ev.displayArtifacts)
      merged.displayArtifacts.push(...ev.displayArtifacts);
    if (ev.files) merged.files.push(...ev.files);
    if (ev.links) merged.links.push(...ev.links);
    if (ev.transcriptions) merged.transcriptions.push(...ev.transcriptions);
    if (ev.commandAliases) merged.commandAliases.push(...ev.commandAliases);
    if (ev.workflowItems) merged.workflowItems.push(...ev.workflowItems);
    if (ev.scheduleTriggers)
      merged.scheduleTriggers.push(...ev.scheduleTriggers);
    if (ev.broadcastActions)
      merged.broadcastActions.push(...ev.broadcastActions);
    if (ev.publishedWorkflowItems)
      merged.publishedWorkflowItems.push(...ev.publishedWorkflowItems);
    if (ev.broadcastRecipients)
      merged.broadcastRecipients.push(...ev.broadcastRecipients);
    if (ev.pam) merged.pam.push(...ev.pam);
    if (ev.templates) merged.templates.push(...ev.templates);
    if (ev.deviceRegistrations)
      merged.deviceRegistrations.push(...ev.deviceRegistrations);
    if (ev.events) merged.events.push(...ev.events);
    if (ev.items) merged.items.push(...ev.items);
    if (ev.feedPreferences) merged.feedPreferences.push(...ev.feedPreferences);
    if (ev.membershipAvailabilities)
      merged.membershipAvailabilities.push(...ev.membershipAvailabilities);
  }
  merged.transcriptions = deduplicateById(merged.transcriptions);
  merged.audioEncodings = deduplicateById(merged.audioEncodings);
  merged.events = deduplicateById(merged.events);
  merged.items = deduplicateById(merged.items);
  merged.membershipAvailabilities = deDuplicateByworkspaceMembershipId(
    merged.membershipAvailabilities,
  );

  return merged;
}

// This interval processes all queues every 1000ms
setInterval(async () => {
  for (const [key, queueData] of queues.entries()) {
    if (queueData.events.length === 0) continue;

    // Get all events and clear the array
    const eventsToProcess = queueData.events;
    queueData.events = [];

    // Merge all queued events into one
    const mergedEvent = mergeEvents(eventsToProcess);

    await appSyncSubscriptionUpdate(
      mergedEvent,
      queueData.accountId,
      queueData.workspaceId,
    );
  }
}, 1000);

export async function appSyncSubscriptionUpdate(
  event: WsAppSyncEvent,
  myAccountId: string,
  workspaceId: string,
) {
  const promises = [];
  for (const i of event?.accounts || []) {
    promises.push(upsertWsAccount(i));
  }
  for (const i of event?.workspaces || []) {
    promises.push(upsertWorkspace(i));
  }
  for (const i of event?.workspaceMemberships || []) {
    promises.push(upsertWorkspaceMembership(i));
  }
  for (const i of event?.directWorkspaceInvitations || []) {
    promises.push(upsertDirectWsInvitation(i));
  }
  for (const i of event?.feeds || []) {
    promises.push(upsertWsFeed(i));
  }
  for (const i of event?.feedGroups || []) {
    promises.push(upsertWsFeedGroup(i));
  }
  for (const i of event?.feedGroupMemberships || []) {
    promises.push(upsertWsFeedGroupMembership(i));
  }
  for (const i of event?.permissions || []) {
    promises.push(upsertWsPermission(i));
  }
  for (const i of event?.displayArtifacts || []) {
    promises.push(upsertWsDisplayArtifact(i));
  }
  for (const i of event?.files || []) {
    promises.push(upsertWsFile(i));
  }
  for (const i of event?.links || []) {
    promises.push(upsertWsLink(i));
  }
  for (const i of event?.commandAliases || []) {
    promises.push(upsertWsCommandAlias(i));
  }
  for (const i of event?.workflowItems || []) {
    promises.push(upsertWsDraft(i));
  }
  for (const i of event?.scheduleTriggers || []) {
    promises.push(upsertWsScheduleTrigger(i));
  }
  for (const i of event?.broadcastActions || []) {
    promises.push(upsertWsBroadcastAction(i));
  }
  for (const i of event?.publishedWorkflowItems || []) {
    promises.push(upsertWsPublishedDraft(i));
  }
  for (const i of event?.broadcastRecipients || []) {
    promises.push(upsertWsBroadcastRecipient(i));
  }
  for (const i of event?.pam || []) {
    promises.push(upsertWsPAM(i));
  }
  for (const i of event?.templates || []) {
    promises.push(upsertWsTemplate(i));
  }
  for (const i of event?.deviceRegistrations || []) {
    promises.push(upsertDeviceRegistration(i));
  }

  if (event?.feedPreferences?.length > 0) {
    promises.push(upsertWsFeedPreferences(event?.feedPreferences || []));
  }

  for (const i of event?.events || []) {
    promises.push(upsertWsEvent(i));
  }

  if (event?.membershipAvailabilities?.length > 0) {
    promises.push(
      createManyWsMembershipAvailability(event?.membershipAvailabilities || []),
    );
  }

  if (event?.audioEncodings?.length > 0) {
    promises.push(bulkUpsertWsAudioEncodings(event?.audioEncodings || []));
  }
  if (event?.transcriptions?.length > 0) {
    promises.push(bulkUpsertWsTranscriptions(event?.transcriptions || []));
  }

  await Promise.all(promises);
  const itemsUpserted =
    event?.items?.length > 0 ? await bulkUpsertWsItems(event.items) : [];

  const feedIdsFromItems =
    itemsUpserted
      .flat()
      .filter((i) => i.firstInsert)
      .map((f) => f.feedId)
      ?.filter((id) => id) || [];

  const feedIdsFromEvents =
    event?.events?.map((f) => f.feedId)?.filter((id) => id) || [];

  const uniqueFeedIds = Array.from(
    new Set([...feedIdsFromItems, ...feedIdsFromEvents]),
  );

  const membership = await db.query.workspaceMembership.findFirst({
    where: and(
      eq(workspaceMembership.accountId, myAccountId),
      eq(workspaceMembership.workspaceId, workspaceId),
    ),
  });

  if (membership && uniqueFeedIds.length > 0) {
    await initializeUnreadsForAllFeeds({
      membership,
      feedIds: uniqueFeedIds,
      alsoSetAsRead: true,
    });
  }
}

let subscription: any;

export async function subscribeToAppSync({
  accountId,
  currentWorkspaceId,
  pubSub,
  client,
}: {
  accountId: string;
  currentWorkspaceId: string;
  pubSub: PubSub;
  client: Client;
}) {
  if (!pubSub || !client) return;
  if (!accountId) return;

  const wkId = currentWorkspaceId || "";
  const key = getQueueKey(accountId, wkId);

  // Ensure there's a queue for this accountId/workspaceId pair
  if (!queues.has(key)) {
    queues.set(key, {
      accountId,
      workspaceId: wkId,
      events: [],
    });
  }

  const channels: string[] = [];
  channels.push(`wsaccount#${accountId}`);
  if (currentWorkspaceId) {
    channels.push(`workspace#${currentWorkspaceId}`);
  }
  const newSub = pubSub.subscribeFilter(
    channels,
    (data?: any) => {
      if (data?.data) {
        const eventData = JSON.parse(data.data) as WsAppSyncEvent;
        const queue = queues.get(key);
        console.log(
          "MEMBERSHIP AVAILABILITY EVENT",
          eventData.membershipAvailabilities,
        );
        if (queue) {
          queue.events.push(eventData);
        } else {
          console.error(
            `No queue found for accountId=${accountId}, workspaceId=${currentWorkspaceId}`,
          );
        }
      } else {
        console.warn(
          "Account Feed Subscription Event called with no data",
          data,
        );
      }
    },
    (e: any) => {
      console.error("Account Feed Subscription Error", e);
    },
  );
  subscription?.unsubscribe();
  subscription = newSub;
}
