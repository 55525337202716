import { client } from "@/config";
import { createManyWsEvent } from "@/data/pg/bulkInserts";
import { db } from "@/db/db";
import { feed, item } from "@/db/schema";
import { downloadFeedItemContent } from "@/models/actions/initialFeedLoad";
import { context, propagation, trace } from "@opentelemetry/api";
import { and, desc, eq } from "drizzle-orm";
import { WorkspaceFeedItemOldestEventsResponse } from "web-client/api/data-contracts";

const EVENTS_PER_PAGE = 10000;

export async function downloadOldestFeedItemEvents(
  itemIds: string[],
  workspaceId: string,
  timestamp?: string,
) {
  let page = 0;
  let count = 0;
  const parallelRequests = 1;
  while (true) {
    const promises: Promise<WorkspaceFeedItemOldestEventsResponse>[] = [];

    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client.getFeedItemOldestEvents(
          workspaceId,
          itemIds,
          currentPage,
          EVENTS_PER_PAGE,
          timestamp,
        ),
      );
      page += 1;
    }

    const responses = await Promise.all(promises);
    for (const response of responses) {
      if (!response) {
        continue;
      }
      await createManyWsEvent(response.events);
    }
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.events.length,
      0,
    );
    count += currentCount;
    if (currentCount % EVENTS_PER_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
}

const fetchLastTenItems = async (feedId: string): Promise<string[]> => {
  const tracer = trace.getTracer("bootstrap");

  const activeContext = propagation.extract(context.active(), {});
  const span = tracer.startSpan("fetchLastTenItems", {}, activeContext);

  const items = await db.query.item
    .findMany({
      where: and(eq(feed.id, feedId), eq(item.deletedAt, null)),
      orderBy: desc(item.createdAt),
      limit: 10,
    })
    .execute();
  span.end();
  return items?.map((item) => item.id) ?? [];
};

export const fetchFeedItemsAndDownloadEvents = async (
  feedId: string,
  workspaceId: string,
) => {
  const itemsMap = await fetchLastTenItems(feedId);
  if (itemsMap?.length > 0) {
    return await downloadFeedItemContent(workspaceId, itemsMap);
  }
};
