import FeedVirtualizedList from "@/components/FeedVirtualizedList";
import NoFeedItems from "@/components/NoFeedItems";
import { FeedContext } from "@/models/FeedContextProvider";
import { Dispatch, SetStateAction, useContext } from "react";
import FeedFooter from "./FeedFooter";

export default function Feed({
  setRenderToggle,
}: { setRenderToggle: Dispatch<SetStateAction<string>> }) {
  const { feedMap, currentFeed } = useContext(FeedContext);

  const hasContent = feedMap?.length > 0;

  return (
    <>
      {hasContent ? (
        <FeedVirtualizedList setRenderToggle={setRenderToggle} />
      ) : (
        <NoFeedItems size={feedMap?.length} feedId={currentFeed?.id} />
      )}
      <FeedFooter />
    </>
  );
}
