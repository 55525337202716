import TemplateIcon from "@/components/Icons/TemplateIcon";
import TrashOutlined from "@/components/Icons/TrashOutlined";
import InvalidUser from "@/components/InvalidUser";
import Loading from "@/components/Loading/Index";
import ConfirmDiscardChanges from "@/components/ModalForms/ConfirmDiscardChanges";
import Pagination from "@/components/Pagination";
import TextInputHelper from "@/components/TextInputHelper";
import { FullInput } from "@/components/Utils";
import { client } from "@/config";
import { upsertWsTemplate } from "@/data/pg/updates";
import { db } from "@/db/db";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { template as templateTable } from "@/db/schema";
import ModalForm from "@/elements/ModalForm";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import { UxContext } from "@/models/UxStateProvider";
import { cachedTemplatesSelect } from "@/models/commonQueries";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { eq } from "drizzle-orm";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WsTemplate } from "web-client/api/data-contracts";

export default function TemplateManagement() {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);
  const [templateId, setTemplateId] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string>("");
  const [activeTemplate, setActiveTemplate] = useState<WsTemplate | null>(null);
  const [templateText, setTemplateText] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [templates, setTemplates] = useState<Array<WsTemplate>>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [confirmUnsavedChanges, setConfirmUnsavedChanges] =
    useState<boolean>(false);
  const [activePage, setActivePage] = useState(1);
  const [templatesPerPage, setTemplatesPerPage] = useState(20);
  const characterLimit = 5000;

  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const theme = useTheme();
  const { availableWorkspaceRoles } = useContext(DataContext);
  const { setNavTitle } = useContext(UxContext);
  const newTemplate = templateId === "new-template";

  const openNewTemplateModal = () => {
    setTemplateId(() => "new-template");
    setTemplateName(() => "");
    setTemplateText(() => "");
    setUnsavedChanges(() => false);
  };

  const confirmDelete = (id: string) => {
    setConfirmDeleteDialog(() => true);
    setDeleteId(() => id);
  };

  const confirmClose = () => {
    setUnsavedChanges(() => false);
    setConfirmUnsavedChanges(() => false);
    setTemplateId(() => "");
  };

  const editTemplate = (id: string) => {
    const findActiveTemplate = templates.find((template) => template.id === id);
    setActiveTemplate(() => findActiveTemplate);
    setTemplateText(() => findActiveTemplate.template);
    setTemplateName(() => findActiveTemplate.name);
    setUnsavedChanges(() => false);
    setTemplateId(() => id);
  };

  const handleDelete = async () => {
    setDisabled(() => true);
    await client.deleteWorkspaceTemplate({ workspaceId, templateId: deleteId });
    setTemplates((array) => array.filter((item) => item.id !== deleteId));
    setDisabled(() => false);
    setDeleteId(() => "");
    setConfirmDeleteDialog(() => false);
    await db.delete(templateTable).where(eq(templateTable.id, deleteId));
  };

  const formCreateNewTemplate = async (e) => {
    e.preventDefault();
    try {
      setDisabled(() => true);
      const { template } = await client.createWorkspaceTemplate({
        workspaceId,
        name: templateName,
        template: templateText,
      });
      setDisabled(() => false);
      setTemplates((array) => [...array, template]);
      setTemplateId(() => "");
      setTemplateName(() => "");
      setTemplateText(() => "");
      await upsertWsTemplate(template);
    } catch (e) {
      console.error("Could not create template");
    }
  };

  const formEditTemplate = async (e) => {
    e.preventDefault();
    try {
      setDisabled(() => true);
      const { template } = await client.updateWorkspaceTemplate({
        workspaceId,
        templateId,
        name: templateName,
        template: templateText,
      });
      setTemplates((array) =>
        array.map((item) => (item.id === templateId ? template : item)),
      );
      setDisabled(() => false);
      setTemplateId(() => "");
      await upsertWsTemplate(template);
    } catch (e) {
      console.error("Could not edit template");
    }
  };

  const handleTemplateName = (e) => {
    const newName = e.target.value;
    setTemplateName(newName);
    if (activeTemplate?.name !== newName) {
      setUnsavedChanges(() => true);
    }
  };

  const handleTemplateText = (e) => {
    const newText = e.target.value;
    setTemplateText(newText);
    if (activeTemplate?.template !== newText) {
      setUnsavedChanges(() => true);
    }
  };

  /**
   * Handle Command Enter when in a text box
   * @param e
   */
  const onKeyDown = (e) => {
    if (e.metaKey && e.which === 13) {
      newTemplate ? formCreateNewTemplate(e) : formEditTemplate(e);
    }
  };

  const { rows: cachedTemplates } = useDrizzleSelect(
    cachedTemplatesSelect({ workspaceId }),
  );

  useEffect(() => {
    if (cachedTemplates?.length > 0) {
      setLoadingTemplates(() => false);
      return setTemplates(() => cachedTemplates);
    } else {
      setLoadingTemplates(() => true);
      setTimeout(() => {
        setLoadingTemplates(() => false);
      }, 5000);
    }
  }, [cachedTemplates]);

  const denyClose = () => {
    setConfirmUnsavedChanges(false);
    setConfirmDeleteDialog(false);
  };

  const handleClose = () => {
    if (unsavedChanges) {
      setConfirmUnsavedChanges(() => true);
    } else {
      confirmClose();
    }
  };

  const handlePerPageChange = (page) => {
    setActivePage(page);
  };

  const handleTemplatesPageChange = (perPage) => {
    setTemplatesPerPage(perPage);
  };

  useEffect(() => {
    setNavTitle("Templates");
  }, [setNavTitle]);

  return (
    <>
      <InvalidUser
        validRoles={[
          availableWorkspaceRoles?.get("admin")?.role,
          availableWorkspaceRoles?.get("member")?.role,
        ]}
      />
      <>
        <ModalForm
          id="template-modal"
          open={templateId !== ""}
          onClose={handleClose}
          disableClose={disabled}
          noPadding
          sx={{ scrollbarGutter: "auto", overflow: "hidden !important" }}
          data-testid={Locator.workspaceNav.templates.modal.type(
            newTemplate ? "create" : "edit",
          )}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: `solid 1px ${theme.palette.secondary.main}`,
                py: 2,
                px: 4,
                mb: 2,
                position: "sticky",
                top: 0,
              }}
            >
              <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
                {newTemplate ? "New Template" : "Edit Template"}
              </Typography>
            </Box>

            <Stack
              component="form"
              sx={{ px: 2, pb: 2, gap: 2 }}
              onSubmit={(e) =>
                newTemplate ? formCreateNewTemplate(e) : formEditTemplate(e)
              }
            >
              <Box>
                <FullInput
                  id="newTemplateName"
                  placeholder="Enter a title"
                  autoFocus
                  required
                  className="input-v2"
                  disabled={disabled}
                  value={templateName}
                  callback={handleTemplateName}
                  aria-label={Locator.workspaceNav.templates.modal.name}
                />
              </Box>
              <Box>
                <FullInput
                  id="createNewTemplate"
                  disabled={disabled}
                  className="text-input-v2"
                  multiline
                  required
                  placeholder="Start typing your templated message"
                  rows={7}
                  inputProps={{
                    className: "scrollable-content",
                  }}
                  value={templateText}
                  callback={handleTemplateText}
                  onKeyDown={onKeyDown}
                  helperText={
                    <TextInputHelper
                      textInput={templateText}
                      limit={characterLimit}
                    />
                  }
                  aria-label={Locator.workspaceNav.templates.modal.content}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  sx={{ width: "auto" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={disabled}
                  disabled={
                    (templateName?.length === 0 &&
                      templateText?.length === 0) ||
                    templateText?.length > characterLimit ||
                    !unsavedChanges
                  }
                  aria-label={Locator.workspaceNav.templates.modal.save}
                >
                  Save
                </LoadingButton>
              </Box>
            </Stack>
          </Box>
        </ModalForm>

        <ConfirmDiscardChanges
          id="delete-template-confirmation"
          label="Are you sure?"
          text="Confirm you would like to delete this template. Your content will be lost."
          deleteText="Delete"
          visible={confirmDeleteDialog}
          denyCloseHandler={denyClose}
          confirmCloseHandler={handleDelete}
          ariaLabel={Locator.workspaceNav.templates.confirmDelete}
        />
        <ConfirmDiscardChanges
          id="discard-unsaved-template-confirmation"
          text="Confirm you would like to discard changes to this template. Your content will be lost."
          visible={confirmUnsavedChanges}
          denyCloseHandler={denyClose}
          confirmCloseHandler={confirmClose}
        />
        <Stack
          sx={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            pt: 8,
            px: 2,
          }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              maxWidth: 1300,
              alignItems: "center",
              gap: 2,
              overflow: "hidden",
            }}
          >
            <Stack
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                justifyContent: { xs: "flex-start", sm: "space-between" },
                width: "100%",
                gap: 2,
              }}
            >
              <Typography
                component="h3"
                sx={{ fontSize: "1.75rem", fontWeight: 700 }}
              >
                All templates ({templates?.length || 0})
              </Typography>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ px: 6, gap: 1, alignSelf: "flex-end" }}
                  onClick={() => openNewTemplateModal()}
                  aria-label={Locator.workspaceNav.templates.createTemplate}
                >
                  <TemplateIcon role="img" /> New Template
                </Button>
              </Box>
            </Stack>
            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.75px",
                borderColor: theme.palette.secondary.light,
                mt: 3,
                mb: 2,
              }}
            />
            {loadingTemplates ? (
              <Stack sx={{ alignItems: "center", width: "100%" }}>
                <Loading />
              </Stack>
            ) : (
              <Stack
                sx={{ width: "100%", height: "100%", position: "relative" }}
                className="scrollable-content"
              >
                {templates?.length > 0 ? (
                  <>
                    <Stack
                      sx={{
                        width: "100%",
                        height: "auto",
                        gap: 1,
                        position: "relative",
                        "& .template-element:last-of-type": {
                          marginBottom: "32px",
                        },
                        flexGrow: 1,
                      }}
                    >
                      {templates
                        .slice(
                          (activePage - 1) * templatesPerPage,
                          activePage * templatesPerPage - 1,
                        )
                        .map((template, index) => (
                          <Box
                            className="template-element"
                            key={template.id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              background:
                                template.id === templateId &&
                                theme.palette.secondary.main,
                              borderRadius: "12px",
                              "&:hover": {
                                background: theme.palette.secondary.main,
                                cursor: "pointer",
                              },
                            }}
                            aria-label={Locator.workspaceNav.templates.listItem}
                          >
                            <Button
                              aria-label={
                                Locator.workspaceNav.templates.editTemplate
                              }
                              sx={{
                                flexGrow: "1",
                                gap: 1,
                                textTransform: "none",
                                pl: 2,
                                justifyContent: "flex-start",
                                fontSize: "1.25rem",
                                fontWeight: 700,
                                "&:hover": {
                                  background: "transparent",
                                },
                              }}
                              onClick={() => editTemplate(template.id)}
                            >
                              {`${index + 1}.`} {template.name}
                            </Button>
                            <Box sx={{ flex: "0 1 auto", display: "flex" }}>
                              <Link
                                component="button"
                                sx={{ fontWeight: 600 }}
                                onClick={() => editTemplate(template.id)}
                              >
                                Edit
                              </Link>
                              <Button
                                onClick={() => confirmDelete(template.id)}
                                sx={{
                                  "&:hover": {
                                    background: "transparent",
                                  },
                                }}
                                aria-label={
                                  Locator.workspaceNav.templates.deleteTemplate
                                }
                              >
                                <TrashOutlined role="img" />
                              </Button>
                            </Box>
                          </Box>
                        ))}
                    </Stack>
                    <Stack
                      sx={{
                        width: "100%",
                        position: "sticky",
                        bottom: 16,
                        alignItems: "center",
                      }}
                    >
                      <Pagination
                        itemsCount={templates?.length || 0}
                        onPageChange={handlePerPageChange}
                        onRowsPerPageChange={handleTemplatesPageChange}
                      />
                    </Stack>
                  </>
                ) : (
                  <Stack
                    sx={{
                      width: "100%",
                      py: 1.5,
                      px: 2,
                      background: theme.palette.secondary.main,
                      borderRadius: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      Click <b>“New template”</b> to create a templated message.
                    </Typography>
                    <Typography>
                      Templated messages can be saved and used in text-to-speech
                      messages.
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </>
    </>
  );
}
