import ListIcon from "@/components/Icons/ListIcon";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import {
  subscribeToFeed,
  subscribeToFeedGroup,
  unsubscribeFromFeed,
  unsubscribeFromFeedGroup,
} from "@/models/actions/clientActions";
import {
  ChannelList,
  fetchAllGroupedChannelsQuery,
  fetchAllGroupsWithSubscribedStatusQuery,
  fetchAllNonGroupedChannelsQuery,
} from "@/models/commonQueries";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box, MenuItem } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  channel: ChannelList;
  workspaceMembershipId: string;
  handleClose: () => void;
  channelUrl: string;
  readOnlyMode: boolean;
}

export default function LinkContextMenu({
  channel,
  workspaceMembershipId,
  handleClose,
  channelUrl,
  readOnlyMode,
}: Props) {
  const navigate = useNavigate();

  const { ampli } = useContext(TrackingContext);
  const { fetchNextActiveChannel } = useContext(LiveQueryContext);
  const { accountEvent } = useContext(ActionContext);
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);
  const { currentFeedId: feedId } = useContext(CurrentFeedContext);

  const [disabled, setDisabled] = useState<boolean>(false);
  const isGrouped = !!channel?.groupId;
  const [checkedSubscribeStatus, setCheckedSubscribeStatus] =
    useState<boolean>(false);
  const [subscribedAll, setSubscribedAll] = useState<boolean>(false);
  const [subscribedSome, setSubscribedSome] = useState<boolean>(false);

  useEffect(() => {
    if (!channel.groupId) {
      setCheckedSubscribeStatus(() => true);
      return;
    }
    fetchAllGroupsWithSubscribedStatusQuery({
      workspaceMembershipId,
      groupId: channel.groupId,
    }).then((groupedChannels) => {
      setCheckedSubscribeStatus(() => true);
      setSubscribedAll(() =>
        groupedChannels.every((channel) => channel.subscribed),
      );
      setSubscribedSome(() =>
        groupedChannels.some((channel) => channel.subscribed),
      );
    });
  }, [channel.id]);

  const markChannelAsRead = async () => {
    handleClose();
    accountEvent("Marked Feed as Read", {
      feedId: channel.id,
    });
    setDisabled(() => false);
  };

  const subscribeChannel = async (id: string, redirect: boolean) => {
    setDisabled(() => true);
    handleClose();
    await subscribeToFeed({ workspaceId, feedId: id, workspaceMembershipId });
    setDisabled(() => false);
    if (feedId !== id && redirect) {
      navigate(channelUrl);
    }
  };

  const unsubscribeChannel = async () => {
    setDisabled(() => true);
    handleClose();
    await unsubscribeFromFeed({
      workspaceId,
      feedId: channel.id,
      workspaceMembershipId,
    });
    const url = await fetchNextActiveChannel();
    const leavingActiveChannel = channel.id === feedId;
    if (leavingActiveChannel) {
      navigate(url);
    }
    setDisabled(() => false);
  };

  const subscribeGroup = async () => {
    setDisabled(() => true);
    handleClose();
    ampli.groupJoin({ workspaceId });
    await subscribeToFeedGroup({
      workspaceId,
      feedGroupId: channel.groupId,
      workspaceMembershipId,
    });
    setDisabled(() => false);
  };

  const unsubscribeGroup = async () => {
    setDisabled(() => true);
    ampli.groupLeave({ workspaceId });
    handleClose();
    await unsubscribeFromFeedGroup({
      workspaceId,
      feedGroupId: channel.groupId,
      workspaceMembershipId,
    });
    const url = await fetchNextActiveChannel();
    setDisabled(() => false);
    if (url) {
      navigate(url);
    }
  };

  const markAllAsRead = async () => {
    setDisabled(() => true);

    /**
     * If the item is a group only select grouped items
     * If it's not a group only select non-grouped items
     */
    handleClose();
    const feeds = channel?.groupId
      ? await fetchAllGroupedChannelsQuery({
          workspaceMembershipId,
          groupId: channel.groupId,
        })
      : await fetchAllNonGroupedChannelsQuery({ workspaceMembershipId });
    if (feeds.length > 0) {
      for (const feedRecord of feeds) {
        accountEvent("Marked Feed as Read", {
          feedId: feedRecord.id,
        });
      }
    }

    setDisabled(() => false);
  };

  if (!checkedSubscribeStatus) {
    return <Box>...</Box>;
  }

  return (
    <Box className="new-channel-item-context-menu">
      {!readOnlyMode && channel?.unread && channel?.subscribed && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.markAsRead}
          disabled={disabled}
          onClick={markChannelAsRead}
        >
          <Box className="new-channel-context-button">
            <DraftsOutlinedIcon />
          </Box>
          <Box>Mark As Read</Box>
        </MenuItem>
      )}
      {!readOnlyMode && channel?.unread && channel?.subscribed && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.markAllAsRead}
          disabled={disabled}
          onClick={markAllAsRead}
        >
          <Box className="new-channel-context-button">
            <ListIcon />
          </Box>
          {channel?.groupId ? (
            <Box>Mark Group as read</Box>
          ) : (
            <Box>Mark all as Read</Box>
          )}
        </MenuItem>
      )}

      {isGrouped && !subscribedAll && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.groups.subscribe}
          disabled={disabled}
          onClick={subscribeGroup}
        >
          <Box className="new-channel-context-button">
            <LoginIcon />
          </Box>
          <Box>Subscribe to notifications for all channels in this group</Box>
        </MenuItem>
      )}

      {isGrouped && subscribedSome && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.groups.unsubscribe}
          disabled={disabled}
          onClick={unsubscribeGroup}
        >
          <Box className="new-channel-context-button">
            <LogoutIcon />
          </Box>
          <Box>
            Unsubscribe from notifications for all channels in this group
          </Box>
        </MenuItem>
      )}

      {channel?.subscribed ? (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.leave}
          disabled={disabled}
          onClick={unsubscribeChannel}
        >
          <Box className="new-channel-context-button">
            <LogoutIcon />
          </Box>
          <Box>Unsubscribe from notifications for this channel</Box>
        </MenuItem>
      ) : (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.subscribe}
          disabled={disabled}
          onClick={() => subscribeChannel(channel.id, false)}
        >
          <Box className="new-channel-context-button">
            <LoginIcon />
          </Box>
          <Box>Subscribe to notifications for this channel</Box>
        </MenuItem>
      )}
    </Box>
  );
}
