import { client } from "@/config";
import { upsertAccountEvent } from "@/data/oldWorld";
import { db } from "@/db/db";
import { accountEvent as accountEventTable, item } from "@/db/schema";
import { UxContext } from "@/models/UxStateProvider";
import cuid from "cuid";
import { and, eq } from "drizzle-orm";
import React, { createContext, useCallback, useContext } from "react";
import {
  AccountEventRequest,
  AccountEventType,
  PublishBroadcastResponse,
} from "web-client/api/data-contracts";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import { TelemetryContext } from "./TelemetryProvider";
import { initializeUnreadsForAllFeeds } from "./actions/initialFeedLoad";

type ActionState = {
  accountEvent?: (event: AccountEventType, data: any) => void;
  publishBroadcast?: (params: {
    workspaceId: string;
    contentId: string;
    workspaceMembershipIds?: string[];
    feedIds?: string[];
    inputText?: string;
  }) => Promise<PublishBroadcastResponse>;
  sendFeedback?: (message: string) => Promise<void>;
};

export const ActionContext = createContext<ActionState>({});

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const ActionsProvider = ({ children }: Props) => {
  const { userReadOnlyMode } = useContext(UxContext);
  const { trackAction, finishAction } = useContext(TelemetryContext);
  const { myCurrentWorkspaceMembership } = useContext(WorkspaceContext);

  const accountEvent = useCallback(
    async (name: AccountEventType, data: any) => {
      if (userReadOnlyMode === true) {
        console.log("Read only mode enabled, not sending event");
        return;
      }

      if (!myCurrentWorkspaceMembership) return;
      const myAcocuntId = myCurrentWorkspaceMembership.accountId;
      if (data.feedId && data.feedItemId) {
        const existingEvent = await db.query.accountEvent
          .findFirst({
            where: and(
              eq(accountEventTable.name, name),
              eq(accountEventTable.feedId, data.feedId),
              eq(accountEventTable.itemId, data.feedItemId),
              eq(accountEventTable.accountId, myAcocuntId),
            ),
          })
          .execute();

        if (existingEvent) {
          console.log("Already sent event", existingEvent);
          return await db
            .update(item)
            // @ts-ignore
            .set({ hasRegisteredSeenEvent: true })
            .where(eq(item.id, data.feedItemId))
            .execute();
        }
      }
      const event: AccountEventRequest = {
        id: cuid(),
        name,
        ...data,
        timestamp: new Date(Date.now()).toISOString(),
        accountId: myAcocuntId,
      };
      client.createAccountEvent({
        ...event,
      });
      await upsertAccountEvent(event);
      await initializeUnreadsForAllFeeds({
        feedIds: [event.feedId],
        membership: myCurrentWorkspaceMembership,
        alsoSetAsRead: true,
      });
    },
    [myCurrentWorkspaceMembership, userReadOnlyMode],
  );

  const publishBroadcast = useCallback(
    async ({
      workspaceId,
      contentId,
      workspaceMembershipIds,
      feedIds,
      inputText,
    }: {
      workspaceId: string;
      contentId: string;
      workspaceMembershipIds?: string[];
      feedIds?: string[];
      inputText?: string;
    }) => {
      trackAction(
        { action: "publish_broadcast", target: contentId },
        {
          workspaceId,
          workspaceMembershipIds,
          feedIds,
          inputText,
        },
      );
      const broadcast = await client.publishBroadcast({
        workspaceId,
        contentId,
        workspaceMembershipIds,
        feedIds,
        inputText,
      });
      finishAction({ action: "publish_broadcast", target: contentId });

      return broadcast;
    },
    [trackAction, finishAction],
  );

  const sendFeedback = useCallback(async (message: string) => {
    const feedback = await client.uploadLogs({
      logs: "",
      userMessage: message,
    });

    return feedback;
  }, []);

  const actionState: ActionState = {
    accountEvent,
    publishBroadcast,
    sendFeedback,
  };

  return (
    <ActionContext.Provider value={actionState}>
      {children}
    </ActionContext.Provider>
  );
};

export default ActionsProvider;
