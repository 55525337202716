import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import UseTimeouts from "@/hooks/useTimeouts";
import { DataContext } from "@/models/DataProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useEffect, useRef } from "react";

/**
 * This hook is used to check the hands-free status of users
 * It uses a visible:ref as a value to see if the app has been mounted and data loaded
 * @param visible - boolean to check if the component is visible
 */
export default function UseWorkspaceMembershipAbilities(visible: boolean) {
  const { fetchWorkspaceMembershipAvailabilities } = useContext(DataContext);
  const { handsFreeStatusIndicator } = useFlags();
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const handsFreeAllowed = handsFreeEnabledForWorkspace(currentWorkspaceId);
  const firstBootCheck = useRef<boolean>(false);
  const handsFreeRefresh = UseTimeouts(60);

  // check on first mount and then not again
  useEffect(() => {
    if (!firstBootCheck.current && visible && currentWorkspaceId) {
      firstBootCheck.current = true;
      fetchWorkspaceMembershipAvailabilities(currentWorkspaceId).then().catch();
    }
  }, [visible, currentWorkspaceId]);

  // only check when a new handsFreeRefresh is requested -> 65 seconds
  useEffect(() => {
    if (
      visible &&
      handsFreeAllowed &&
      handsFreeStatusIndicator &&
      handsFreeRefresh?.refresh === true &&
      currentWorkspaceId
    ) {
      fetchWorkspaceMembershipAvailabilities(currentWorkspaceId).then().catch();
    }
  }, [
    handsFreeAllowed,
    handsFreeStatusIndicator,
    handsFreeRefresh?.refresh,
    currentWorkspaceId,
  ]);
}
