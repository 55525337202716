import type { SxProps } from "@mui/material";
import Box from "@mui/material/Box";

export default function StatusLight({
  color,
  size,
  sx,
}: { color: string; size: number; sx?: SxProps }) {
  const sizePx = `${size}px`;

  return (
    <Box
      sx={{
        background: color,
        display: "inline-block",
        minWidth: sizePx,
        minHeight: sizePx,
        width: sizePx,
        height: sizePx,
        borderRadius: "100%",
        ...sx,
      }}
    />
  );
}
