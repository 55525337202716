import SearchResults from "@/components/Search/SearchResults";
import {
  PaginatedSearchData,
  mutateSearchResponse,
  paginateData,
} from "@/components/Search/search";
import { FullInput } from "@/components/Utils";
import { client } from "@/config";
import { DataContext } from "@/models/DataProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import * as Icons from "@mui/icons-material";
import { Box, Fade, IconButton, InputLabel, useTheme } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { type FormEvent, useContext, useEffect, useRef, useState } from "react";
import "./styles.css";

export default function SearchContainer() {
  const theme = useTheme();
  const { ampli } = useContext(TrackingContext);
  const { globalSearch } = useFlags();
  const { preferredLanguage } = useContext(DataContext);
  const { globalSearchOpen, setGlobalSearchOpen, toolBarSize, leftNavOpen } =
    useContext(UxContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disableShowMore, setDisabledShowMore] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [channelsPage, setChannelsPage] = useState<number>(0);
  const [queryString, setQueryString] = useState<string>("");
  const [staticQueryString, setStaticQueryString] = useState<string>("");
  const [messages, setMessages] = useState<PaginatedSearchData>(
    {} as PaginatedSearchData,
  );
  const [channels, setChannels] = useState<PaginatedSearchData>(
    {} as PaginatedSearchData,
  );
  const [disableEnterKey, setDisableEnterKey] = useState<boolean>(false);
  const [resultsIndex, setResultsIndex] = useState<number | null>(null);
  const globalSearchInputRef = useRef<HTMLInputElement | null>(null);
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);

  const limit = 10;
  const channelLimit = 4;
  const width = toolBarSize.offsetWidth;
  const marginLeft = leftNavOpen
    ? 390 + toolBarSize.offsetLeft
    : toolBarSize.offsetLeft;

  const closeGlobalSearch = () => {
    setGlobalSearchOpen(() => false);
    setMessages(() => ({}) as PaginatedSearchData);
    setChannels(() => ({}) as PaginatedSearchData);
  };

  const keyDownHandler = (event: KeyboardEvent) => {
    event.stopPropagation();
    if ((event.ctrlKey || event.metaKey) && event.key === "k") {
      event.preventDefault();
      setGlobalSearchOpen(() => true);
    } else if (event.key === "Escape" && globalSearchOpen) {
      closeGlobalSearch();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyDownHandler]);

  const setInputFocus = (value: boolean) => {
    const input = document.getElementById("globalSearchInput");
    if (value === true) {
      input?.focus();
    } else {
      input?.blur();
    }
    setDisableEnterKey(() => value);
  };

  const submit = async (e: FormEvent | null) => {
    if (e) {
      e.preventDefault();
    }
    if (queryString?.length === 0) {
      return;
    }
    setChannels(() => ({}) as PaginatedSearchData);
    setMessages(() => ({}) as PaginatedSearchData);
    setPage(() => 0);
    setChannelsPage(() => 0);
    ampli.searchExecute({ workspaceId });
    setLoading(() => true);
    setHasSearched(() => true);
    setDisabledShowMore(() => false);
    setResultsIndex(() => -1);

    const messagesResponse = await client.searchMessages(workspaceId, {
      query: queryString,
      languages: [preferredLanguage],
      limit,
      offset: 0,
    });
    const mutatedMessages = mutateSearchResponse({
      response: messagesResponse,
      query: queryString,
      limit,
      page: 0,
    });
    setNoResults(() => mutatedMessages?.results?.length === 0);

    setMessages(() => mutatedMessages);

    const channelsResponse = await client.searchChannels(workspaceId, {
      query: queryString,
      limit: channelLimit,
      offset: 0,
    });
    const mutatedChannels = mutateSearchResponse({
      response: channelsResponse,
      query: queryString,
      limit: channelLimit,
      page: 0,
    });
    setNoResults(
      () =>
        mutatedChannels?.results?.length === 0 &&
        mutatedMessages?.results?.length === 0,
    );
    setChannels(() => mutatedChannels);
    setStaticQueryString(() => queryString);
    setPage((value) => value + 1);
    setChannelsPage((value) => value + 1);
    setLoading(() => false);
    setTimeout(() => {
      setResultsIndex(() => null);
    }, 250);
  };

  const loadMore = async () => {
    if (disabled) {
      return;
    }
    setDisabled(() => true);
    setPage((value) => value + 1);

    // fetch the data
    const response = await client.searchMessages(workspaceId, {
      query: queryString,
      languages: [preferredLanguage],
      limit,
      offset: messages.meta.offset,
    });

    // mutate the data
    const mutatedMessages = mutateSearchResponse({
      response,
      query: queryString,
      limit,
      page,
    });

    // paginate the data
    const paginatedMessages = paginateData({
      currentData: messages,
      newData: mutatedMessages,
    });
    setMessages(() => paginatedMessages);
    setDisabled(() => false);
  };

  const loadMoreChannels = async () => {
    setDisabledShowMore(() => true);
    setChannelsPage((value) => value + 1);
    const response = await client.searchChannels(workspaceId, {
      query: queryString,
      limit: channelLimit,
      offset: channels.meta.offset,
    });

    const mutatedChannels = mutateSearchResponse({
      response: response,
      query: queryString,
      limit: channelLimit,
      page: channelsPage,
    });

    const paginatedChannels = paginateData({
      currentData: channels,
      newData: mutatedChannels,
    });

    setChannels(() => paginatedChannels);
    setDisabledShowMore(() => false);
  };

  const resetValue = () => {
    setHasSearched(() => false);
    setQueryString(() => "");
    setMessages(() => ({}) as PaginatedSearchData);
    setChannels(() => ({}) as PaginatedSearchData);
    const input =
      globalSearchInputRef?.current?.children[0]?.querySelectorAll("input")[0];
    if (input) {
      input.focus();
    }
  };

  useEffect(() => {
    if (!globalSearchOpen && queryString) {
      resetValue();
    }
  }, [globalSearchOpen, queryString, resetValue]);

  if (!globalSearch) return null;
  return (
    <>
      {globalSearchOpen && (
        <Box className="global-search-container">
          <Fade in={globalSearchOpen} timeout={500}>
            <Box
              className="global-search-shell"
              sx={{
                width: `${width}px`,
                marginLeft: `${marginLeft}px`,
              }}
            >
              <Box
                sx={{
                  background: theme.palette.secondary.dark,
                  borderRadius: 4,
                }}
              >
                <Box component="form" onSubmit={(e) => submit(e)}>
                  <Box
                    sx={{
                      padding: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel
                      htmlFor={"globalSearchInput"}
                      sx={{
                        flex: "0 1 auto",
                        display: "flex",
                        alignItems: "center",
                        pl: 1,
                        mr: 1,
                      }}
                    >
                      <Icons.Search role="img" />
                    </InputLabel>
                    <Box sx={{ flexGrow: 1 }} ref={globalSearchInputRef}>
                      <FullInput
                        id="globalSearchInput"
                        onChange={(e) => setQueryString(() => e?.target?.value)}
                        onClick={() => {
                          setInputFocus(true);
                        }}
                        placeholder="Search across all channels"
                        autoFocus
                        value={queryString}
                        sx={{ width: "100%" }}
                        inputProps={{
                          autoComplete: "off",
                          sx: {
                            fontSize: "1rem !important",
                            minHeight: "0px !important",
                            height: "auto !important",
                            padding: "0.5rem !important",
                            background: "transparent !important",
                            width: "100%",
                            border: "none !important",
                            outline: "none",
                          },
                        }}
                      />
                    </Box>
                    {queryString?.length > 0 && (
                      <IconButton
                        sx={{
                          background: "transparent",
                          p: "0.3rem !important",
                          "&:hover": {
                            background: "transparent !important",
                          },
                          "&:active": {
                            border: "solid 1px transparent !important",
                          },
                        }}
                        aria-label="Cancel Search"
                        onClick={() => resetValue()}
                      >
                        <Icons.CancelRounded />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                <SearchResults
                  resultsIndex={resultsIndex}
                  disableEnterKey={disableEnterKey}
                  loading={loading}
                  messages={messages}
                  channels={channels}
                  hasSearched={hasSearched}
                  noResults={noResults}
                  query={queryString}
                  staticQueryString={staticQueryString}
                  setBlur={(value) => setInputFocus(value)}
                  loadPaginatedData={() => loadMore()}
                  disabledLoadMoreButton={disabled}
                  loadMoreChannels={() => loadMoreChannels()}
                  initializeSearch={() => submit(null)}
                  disableShowMore={disableShowMore}
                />
              </Box>
            </Box>
          </Fade>

          <Fade in={globalSearchOpen} timeout={250}>
            <Box
              className="global-search-overlay"
              onClick={closeGlobalSearch}
            />
          </Fade>
        </Box>
      )}
    </>
  );
}
