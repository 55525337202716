import {
  Workspace,
  WorkspaceMembership,
  WsAccount,
  WsAudioEncoding,
  WsCommandAlias,
  WsDisplayArtifact,
  WsEvent,
  WsFeed,
  WsFeedGroup,
  WsFeedGroupMembership,
  WsFile,
  WsItem,
  WsLink,
  WsMembershipAvailability,
  WsPAM,
  WsPermission,
  WsTemplate,
  WsTranscription,
} from "web-client/api/data-contracts";

import { transformMemberAvailabilityEvent } from "@/data/pg/updates";
import {
  AudioEncoding,
  DisplayArtifact,
  MembershipAvailability,
  PipelineArtifactMetadata,
  Transcription,
  Workspace as WorkspaceType,
} from "@/db/types";
import { chunkWithMinSize } from "@/utils";
import { sql } from "drizzle-orm";
import { db } from "../../db/db";
import {
  account,
  accountEvent,
  audioEncoding,
  displayArtifact,
  feed,
  feedGroup,
  feedGroupMembership,
  file,
  item,
  link,
  permission,
  pipelineArtifactMetadata,
  template,
  transcription,
  workspace,
  workspaceCommandAlias,
  workspaceMembership,
  wsMembershipAvailability,
} from "../../db/schema";
import { updateWorkspaceConfig } from "../workspaceConfig";

export async function createManyWsItem(records: WsItem[]) {
  if (records.length === 0) return;
  const data = records.map((itemRecord) => ({
    id: itemRecord.id,
    createdAt: itemRecord.createdAt,
    feedId: itemRecord.feedId,
    accountId: itemRecord.accountId,
    contentId: itemRecord?.contentId ?? "", // force a value for contentId if null
    deletedAt: itemRecord.deletedAt || null,
    groupId: itemRecord.groupId || null,
    isSilent: itemRecord.isSilent,
    loadedContent: null,
    unread: null,
    status: null,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);
  // await db.transaction(
  //   async (tx) => {
  //     tx.execute(sql`LOCK TABLE ${item} IN ACCESS EXCLUSIVE MODE`);
  //     for (const chunk of chunkedData) {
  //       try {
  //         await tx.insert(item).values(chunk).onConflictDoNothing().execute();
  //       } catch (e) {
  //         console.error("Error creating items in transaction", e, { chunk });
  //         throw e;
  //       }
  //     }
  //   },
  //   {
  //     isolationLevel: "serializable",
  //     // accessMode: "read write",
  //     // deferrable: true,
  //   },
  // );
  for (const chunk of chunkedData) {
    try {
      await db.insert(item).values(chunk).onConflictDoNothing().execute();
    } catch (e) {
      console.error("Error creating Items", e);
    }
  }
  return;
}

export async function createManyWorkspaceMembership(
  records: WorkspaceMembership[],
) {
  if (records.length === 0) return;
  const data = records.map((workspaceMembershipRecord) => ({
    id: workspaceMembershipRecord.id,
    workspaceId: workspaceMembershipRecord.workspaceId,
    accountId: workspaceMembershipRecord.accountId,
    status: workspaceMembershipRecord.status,
    role: workspaceMembershipRecord.role,
  }));

  try {
    return await db
      .insert(workspaceMembership)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating workspace memberships", e);
  }
}

export async function createManyWsTemplate(records: WsTemplate[]) {
  if (records.length === 0) return;
  const data = records.map((c) => ({
    id: c.id,
    name: c.name || "",
    template: c.template || "",
    workspaceId: c.workspaceId,
    authorId: c.authorId || null,
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
    deletedAt: c.deletedAt || null,
  }));
  try {
    return await db
      .insert(template)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating templates", e);
  }
}

export async function createManyWsAccount(records: WsAccount[]) {
  if (records.length === 0) return;
  const data = records.map((accountRecord) => ({
    id: accountRecord.id,
    name: accountRecord.name || null,
    accountType: accountRecord.accountType || null,
    avatarColor: accountRecord.avatarColor || null,
    firstName: accountRecord.firstName || null,
    lastName: accountRecord.lastName || null,
    email: accountRecord.email || null,
    emailVerified: accountRecord.emailVerified,
    phoneNumber: accountRecord.phoneNumber || null,
    phoneNumberVerified: accountRecord.phoneNumberVerified,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);
  for (const chunk of chunkedData) {
    try {
      await db.insert(account).values(chunk).onConflictDoNothing().execute();
    } catch (e) {
      console.log({ account });
      console.error("Error creating accounts", e);
    }
  }
  return;
}

export async function createManyWsFeedGroup(records: WsFeedGroup[]) {
  if (records.length === 0) return;
  const data = records.map((feedGroupRecord) => ({
    id: feedGroupRecord.id,
    createdAt: feedGroupRecord.createdAt,
    updatedAt: feedGroupRecord?.updatedAt || "",
    workspaceId: feedGroupRecord.workspaceId,
    name: feedGroupRecord.name,
  }));

  try {
    return await db
      .insert(feedGroup)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating feed groups", e);
  }
}

export async function createManyWsFeedGroupMembership(
  records: WsFeedGroupMembership[],
) {
  if (records.length === 0) return;
  const data = records.map((membershipRecord) => ({
    id: membershipRecord.id,
    createdAt: membershipRecord.createdAt,
    updatedAt: membershipRecord?.updatedAt || "",
    groupId: membershipRecord.groupId,
    feedId: membershipRecord.feedId,
    workspaceId: membershipRecord.workspaceId,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);
  for (const chunk of chunkedData) {
    try {
      await db
        .insert(feedGroupMembership)
        .values(chunk)
        .onConflictDoNothing()
        .execute();
    } catch (e) {
      console.log({ feedGroupMembership });
      console.error("Error creating feed group memberships", e);
    }
  }
  return;
}

export async function createManyWsCommandAliases(records: WsCommandAlias[]) {
  if (records.length === 0) return;
  const data = records.map((c) => ({
    id: c.id,
    alias: c.alias,
    workspaceId: c.workspaceId,
    workspaceMembershipId: c.workspaceMembershipId,
    feedId: c.feedId,
    createdAt: c.createdAt,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);
  for (const chunk of chunkedData) {
    try {
      await db
        .insert(workspaceCommandAlias)
        .values(chunk)
        .onConflictDoNothing()
        .execute();
    } catch (e) {
      console.log({ workspaceCommandAlias });
      console.error("Error creating command aliases", e);
    }
  }
  return;
}

export async function createManyWsEvent(records: WsEvent[]) {
  if (records.length === 0) return;
  const data = records.map(transformMemberAvailabilityEvent);

  const chunkedData = chunkWithMinSize(data, 2000);
  for (const chunk of chunkedData) {
    try {
      await db
        .insert(accountEvent)
        .values(chunk)
        .onConflictDoNothing()
        .execute();
    } catch (e) {
      console.log({ accountEvent });
      console.error("Error creating events", e);
    }
  }
  return;
}

export async function createManyWsPermission(records: WsPermission[]) {
  if (records.length === 0) return;
  const data = records.map((p) => ({
    id: p.id,
    workspaceMembershipId: p.workspaceMembershipId,
    feedId: p.feedId,
    workflowItemId: p.workflowItemId,
    name: p.name,
    enabled: p.enabled,
    createdAt: p.createdAt,
    updatedAt: p.updatedAt,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);
  for (const chunk of chunkedData) {
    try {
      await db.insert(permission).values(chunk).onConflictDoNothing().execute();
    } catch (e) {
      console.error("Error creating permissions chunk", e);
    }
  }
  return;
}

export async function createManyWsFeed(records: WsFeed[]) {
  if (records.length === 0) return;
  const data = records.map((feedRecord) => ({
    id: feedRecord.id,
    createdAt: feedRecord.createdAt,
    updatedAt: feedRecord.updatedAt,
    workspaceId: feedRecord.workspaceId,
    readOnly: feedRecord.readOnly,
    lastOpened: feedRecord.lastOpened || null,
    latestActivity: feedRecord.latestActivity || null,
    loadedFirstPage: false,
    loadedLastPage: false,
    loadedEvents: false,
    loadedPermissions: false,
    feedType: feedRecord.feedType,
    title: feedRecord.title || null,
    isPrivate: feedRecord.isPrivate,
    autoJoin: feedRecord?.autoJoin,
    isDm: feedRecord.isDm,
    isSilent: feedRecord?.isSilent,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);
  for (const chunk of chunkedData) {
    try {
      await db.insert(feed).values(chunk).onConflictDoNothing().execute();
    } catch (e) {
      console.error("Error creating feeds", e);
    }
  }
  return;
}

export async function createManyWorkspace(records: Workspace[]) {
  if (records.length === 0) return;
  for (const record of records) {
    updateWorkspaceConfig(record);
  }
  const data: WorkspaceType[] = records.map((workspaceRecord) => ({
    id: workspaceRecord.id,
    name: workspaceRecord.name,
    createdAt: workspaceRecord.createdAt,
    updatedAt: workspaceRecord.updatedAt,
  }));

  try {
    return await db
      .insert(workspace)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating workspaces", e);
  }
}

const enableTranscationsForWrites = false;
const isolationLevel = "repeatable read";

export async function createManyWsTranscription(records: WsTranscription[]) {
  if (records.length === 0) return;
  const data: Transcription[] = records.map((transcriptionRecord) => ({
    id: transcriptionRecord.id,
    createdAt: transcriptionRecord.createdAt,
    contentId: transcriptionRecord.contentId,
    transcriptionContent: transcriptionRecord.transcription,
    transcriptionType: transcriptionRecord.transcriptionType,
    backendModel: transcriptionRecord.model,
    confidence: transcriptionRecord.confidence?.toString(),
    executionTime: transcriptionRecord.executionTime?.toString(),
    language: transcriptionRecord.language,
    priority: transcriptionRecord.priority,
    url: transcriptionRecord.url,
    translatedFrom: transcriptionRecord.translatedFrom,
    format: transcriptionRecord.format,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);

  try {
    for (const chunk of chunkedData) {
      await db
        .insert(transcription)
        .values(chunk)
        .onConflictDoNothing()
        .execute();
    }
  } catch (e) {
    console.error("Error creating transcriptions", e);
  }
}

export async function createManyWsAudioEncoding(records: WsAudioEncoding[]) {
  if (records.length === 0) return;
  const data: AudioEncoding[] = records.map((audioEncodingRecord) => ({
    id: audioEncodingRecord.id,
    contentId: audioEncodingRecord.contentId,
    createdAt: audioEncodingRecord.createdAt,
    codec: audioEncodingRecord.codec,
    duration: audioEncodingRecord.duration.toString(),
    generatedService: audioEncodingRecord.generatedService,
    url: audioEncodingRecord.url,
    generatedVoice: audioEncodingRecord.generatedVoice,
    language: audioEncodingRecord.language,
    mimeType: audioEncodingRecord.mimeType,
    priority: audioEncodingRecord.priority,
    transcriptionId: audioEncodingRecord.transcriptionId,
    transcriptionType: audioEncodingRecord.transcriptionType,
    translatedFrom: audioEncodingRecord.translatedFrom,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);

  try {
    for (const chunk of chunkedData) {
      await db
        .insert(audioEncoding)
        .values(chunk)
        .onConflictDoNothing()
        .execute();
    }
  } catch (e) {
    console.error("Error creating audio encodings", e);
  }
}

export async function createManyWsFile(records: WsFile[]) {
  if (records.length === 0) return;
  try {
    return await db.transaction(async (db) => {
      if (enableTranscationsForWrites) {
        db.setTransaction({
          isolationLevel,
        });
      }
      return await db
        .insert(file)
        .values(records)
        .onConflictDoNothing()
        .execute();
    });
  } catch (e) {
    console.error("Error creating files", e);
  }
}

export async function createManyWsLink(records: WsLink[]) {
  if (records.length === 0) return;
  try {
    return await db.transaction(async (db) => {
      if (enableTranscationsForWrites) {
        db.setTransaction({
          isolationLevel,
        });
      }
      return await db
        .insert(link)
        .values(records)
        .onConflictDoNothing()
        .execute();
    });
  } catch (e) {
    console.error("Error creating links", e);
  }
}

export async function createManyWsDisplayArtifact(
  records: WsDisplayArtifact[],
) {
  if (records.length === 0) return;
  const data: DisplayArtifact[] = records.map((displayRecord) => ({
    id: displayRecord.id,
    contentId: displayRecord.contentId,
    createdAt: displayRecord.createdAt,
    deletedAt: displayRecord.deletedAt,
    description: displayRecord.description,
    title: displayRecord.title,
  }));

  try {
    return await db.transaction(async (db) => {
      if (enableTranscationsForWrites) {
        db.setTransaction({
          isolationLevel,
        });
      }
      return await db
        .insert(displayArtifact)
        .values(data)
        .onConflictDoNothing()
        .execute();
    });
  } catch (e) {
    console.error("Error creating display artifacts", e);
  }
}

export async function createManyWsPAM(records: WsPAM[]) {
  if (records.length === 0) return;

  const data: PipelineArtifactMetadata[] = records.map((pamRecord) => ({
    id: pamRecord.id,
    contentId: pamRecord.contentId,
    createdAt: pamRecord.createdAt,
    updatedAt: pamRecord.updatedAt,
    vadResult: null,
  }));
  try {
    return await db
      .insert(pipelineArtifactMetadata)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating pipeline artifact metadata", e);
  }
}

function transformWsMembershipAvailability(
  record: WsMembershipAvailability,
): MembershipAvailability {
  return {
    workspaceMembershipId: record.workspaceMembershipId,
    workspaceId: record.workspaceId,
    calculatedAvailability: record.calculatedAvailability,
    mostRecentAvailabilityChange: record.mostRecentAvailabilityChange,
    mostRecentHandsFreeStatusEnabled: record.mostRecentHandsFreeStatus?.enabled,
    mostRecentHandsFreeStatusChangedAt:
      record.mostRecentHandsFreeStatus?.changedAt,
    mostRecentHandsFreeStatusUpdatedAt:
      record.mostRecentHandsFreeStatus?.updatedAt,
    mostRecentVolumeVolume: record.mostRecentVolume?.volume,
    mostRecentVolumeAudible: record.mostRecentVolume?.audible,
    mostRecentVolumeChangedAt: record.mostRecentVolume?.changedAt,
    mostRecentVolumeUpdatedAt: record.mostRecentVolume?.updatedAt,
    mostRecentDutyStatusStatus: record.mostRecentDutyStatus?.status,
    mostRecentDutyStatusSource: record.mostRecentDutyStatus?.source,
    mostRecentDutyStatusChangedAt: record.mostRecentDutyStatus?.changedAt,
    mostRecentDutyStatusUpdatedAt: record.mostRecentDutyStatus?.updatedAt,
    mostRecentDrivingStatus: record.mostRecentDrivingStatus?.status,
    mostRecentDrivingStatusChangedAt: record.mostRecentDrivingStatus?.changedAt,
    mostRecentDrivingStatusUpdatedAt: record.mostRecentDrivingStatus?.updatedAt,
    updatedAt: record.updatedAt,
  };
}

export async function createManyWsMembershipAvailability(
  records: WsMembershipAvailability[],
) {
  if (records.length === 0) return;
  const tranformedRecords = records.map(transformWsMembershipAvailability);

  const chunkedData = chunkWithMinSize(tranformedRecords, 1000);

  console.log("MEMBERSHIP AVAILABILITIES", { chunkedData });

  for (const chunk of chunkedData) {
    try {
      await db
        .insert(wsMembershipAvailability)
        .values(chunk)
        .onConflictDoUpdate({
          target: wsMembershipAvailability.workspaceMembershipId,
          set: {
            calculatedAvailability: sql`"excluded"."calculatedAvailability"`,
            mostRecentAvailabilityChange: sql`"excluded"."mostRecentAvailabilityChange"`,

            mostRecentHandsFreeStatusEnabled: sql`"excluded"."mostRecentHandsFreeStatusEnabled"`,
            mostRecentHandsFreeStatusChangedAt: sql`"excluded"."mostRecentHandsFreeStatusChangedAt"`,
            mostRecentHandsFreeStatusUpdatedAt: sql`"excluded"."mostRecentHandsFreeStatusUpdatedAt"`,

            mostRecentVolumeVolume: sql`"excluded"."mostRecentVolumeVolume"`,
            mostRecentVolumeChangedAt: sql`"excluded"."mostRecentVolumeChangedAt"`,
            mostRecentVolumeUpdatedAt: sql`"excluded"."mostRecentVolumeUpdatedAt"`,
            mostRecentVolumeAudible: sql`"excluded"."mostRecentVolumeAudible"`,

            mostRecentDutyStatusStatus: sql`"excluded"."mostRecentDutyStatusStatus"`,
            mostRecentDutyStatusSource: sql`"excluded"."mostRecentDutyStatusSource"`,
            mostRecentDutyStatusChangedAt: sql`"excluded"."mostRecentDutyStatusChangedAt"`,
            mostRecentDutyStatusUpdatedAt: sql`"excluded"."mostRecentDutyStatusUpdatedAt"`,

            mostRecentDrivingStatus: sql`"excluded"."mostRecentDrivingStatus"`,
            mostRecentDrivingStatusChangedAt: sql`"excluded"."mostRecentDrivingStatusChangedAt"`,
            mostRecentDrivingStatusUpdatedAt: sql`"excluded"."mostRecentDrivingStatusUpdatedAt"`,

            updatedAt: sql`"excluded"."updatedAt"`,
            workspaceId: sql`"excluded"."workspaceId"`,
          },
        })
        .execute();
    } catch (e) {
      console.error("Error creating membership availabilities", e, { chunk });
    }
  }
}
