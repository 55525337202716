import {
  useDrizzleIncQuery,
  useDrizzleQuery,
  useDrizzleSelect,
} from "@/db/drizzleUtils";
import {
  AccountEvent,
  AccountWithWsMembershipAvailability,
  AudioEncoding,
  DisplayArtifact,
  Feed,
  File,
  Item,
  Link,
  PipelineArtifactMetadata,
  Transcription,
} from "@/db/types";
import { DataContext } from "@/models/DataProvider";
import { SetStateType } from "@/utils";
import { format } from "date-fns";
import {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { VListHandle } from "virtua";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";
import { MyAccountContext } from "./StateProviders/myAccountProvider";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import {
  eventsForFeedItems,
  getAudioEncoding,
  getDisplayArtifacts,
  getFiles,
  getItems,
  getLinks,
  getPams,
  getTranscription,
  mapForArtifact,
  mapForHasItemId,
} from "./caching";

type FeedStatusMessage = {
  message: string;
  severity: "error" | "success" | "info";
};

export interface FullItem {
  id: string;
  feedId: string;
  contentId: string;
  groupId?: string;
  deletedAt?: string;
  createdAt: string;
  accountId: string;
  status: string;
  mine: boolean;
  label: string;
  key: string;
  feedItemIndex: number;
  lastActiveItem: boolean;
  isFirstDateGroupItem: boolean;
  dateGroupLabel: string;
  lastActiveItemIdIsMine: boolean;
  shortDateStr: string;
  loadedContent: boolean;
  url?: string;
  isSilent?: boolean;
  unread?: boolean;
  transcriptions: Transcription[];
  audioEncodings: AudioEncoding[];
  files: File[];
  links: Link[];
  pam: PipelineArtifactMetadata[];
  displayArtifact: DisplayArtifact;
  fromAppsync?: boolean;
  hasRegisteredSeenEvent?: boolean;

  account?: AccountWithWsMembershipAvailability;
  events: AccountEvent[];
}

type IndexedItem = Item & {
  shortDateStr: string;
  formattedTime: string;
  feedItemIndex: number;
};

type FeedState = {
  vListRef?: MutableRefObject<VListHandle>;
  fileUploadModal?: boolean;
  status?: FeedStatusMessage;
  templatesModal?: boolean;
  itemTranscriptState?: string[];
  setItemTranscriptState?: SetStateType<string[]>;
  itemMessageDetailsState?: Record<string, boolean>;
  scrollToBottomOfFeed?: () => void;
  setFileUploadModal: SetStateType<boolean>;
  setItemMessageDetailsState?: SetStateType<Record<string, boolean>>;
  setTemplatesModal: SetStateType<boolean>;
  setStatus: SetStateType<FeedStatusMessage>;
  items?: Item[];
  feedMap?: FullItem[];
  currentFeed?: Feed;
  // accountMap?: Map<string, AccountWithHFStatus>;
};

export const FeedContext = createContext<FeedState>({
  scrollToBottomOfFeed: () => {},
  setFileUploadModal: () => {},
  setTemplatesModal: () => {},
  setItemMessageDetailsState: () => {},
  setStatus: () => {},
});

const FeedContextProvider = ({ children }) => {
  // context use to persist state in the feed virtualized list until refactor occurs
  const [templatesModal, setTemplatesModal] = useState<boolean>(false);
  const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
  const [itemTranscriptState, setItemTranscriptState] = useState<string[]>([]);
  const [itemMessageDetailsState, setItemMessageDetailsState] = useState<
    Record<string, boolean>
  >({});
  const [status, setStatus] = useState<FeedStatusMessage>(null);
  const vListRef = useRef<VListHandle>(null);

  const scrollToBottomOfFeed = () => {
    if (vListRef?.current) {
      setTimeout(() => {
        vListRef.current?.scrollTo(vListRef.current?.scrollSize);
      }, 500);
    }
  };
  const { myAccount } = useContext(MyAccountContext);
  const { currentFeedId, currentFeed } = useContext(CurrentFeedContext);
  const { accountMap } = useContext(WorkspaceContext);

  const { rows: items } = useDrizzleQuery(getItems(currentFeedId));

  const { preferredLanguage } = useContext(DataContext);
  const { rows: transcriptions } = useDrizzleSelect(
    getTranscription(currentFeedId, preferredLanguage),
    "Feed Context",
  );

  const transcriptionMap = useMemo(
    () => mapForArtifact(transcriptions),
    [transcriptions],
  );

  const { rows: audioEncodings } = useDrizzleSelect(
    getAudioEncoding(currentFeedId),
  );
  const audioEncodingMap = useMemo(
    () => mapForArtifact(audioEncodings),
    [audioEncodings],
  );

  const { rows: displayArtifacts } = useDrizzleSelect(
    getDisplayArtifacts(currentFeedId),
  );
  const displayArtifactMap = useMemo(
    () => mapForArtifact(displayArtifacts),
    [displayArtifacts],
  );

  useEffect(() => {
    console.log("FeedContextProvider: Feed Id", currentFeedId);
  }, [currentFeedId]);

  useEffect(() => {
    console.log("FeedContextProvider: Feed", currentFeed);
  }, [currentFeed]);

  useEffect(() => {
    console.log("FeedContextProvider: items", items);
  }, [items]);

  useEffect(() => {
    console.log("FeedContextProvider: Transcriptions", transcriptions);
  }, [transcriptions]);

  const { rows: links } = useDrizzleSelect(getLinks(currentFeedId));
  const linksMap = useMemo(() => mapForArtifact(links), [links]);

  const { rows: files } = useDrizzleSelect(getFiles(currentFeedId));
  const filesMap = useMemo(() => mapForArtifact(files), [files]);

  const { rows: events } = useDrizzleSelect(eventsForFeedItems(currentFeedId));
  const eventsMap = useMemo(() => mapForHasItemId(events), [events]);

  const { rows: pams } = useDrizzleSelect(getPams(currentFeedId));
  const pamMap = useMemo(() => mapForArtifact(pams), [pams]);

  const feedMap = useMemo(() => {
    const dateFormat = (publishedDate) => {
      const date = new Date(publishedDate);
      const dateFormat = "MMM d, yyyy";
      const shortDateStr = format(date, "MMddyyyy");
      const formattedDate = format(date, dateFormat);
      const formattedTime = format(date, "h:mm aa");
      return {
        shortDateStr,
        formattedDate,
        formattedTime,
      };
    };

    const indexedItems = new Map<string, IndexedItem[]>();

    items?.forEach((feedItem, index) => {
      const { shortDateStr, formattedTime } = dateFormat(feedItem.createdAt);

      const item = {
        ...feedItem,
        shortDateStr,
        feedItemIndex: index,
        formattedTime,
      };
      if (indexedItems.has(shortDateStr)) {
        indexedItems.set(shortDateStr, [
          ...indexedItems.get(shortDateStr),
          item,
        ]);
      } else {
        indexedItems.set(shortDateStr, [item]);
      }
    });

    const ret =
      items?.map((feedItem, index): FullItem => {
        const { shortDateStr, formattedTime } = dateFormat(feedItem.createdAt);

        const firstItemForDate = indexedItems.get(shortDateStr)?.[0];

        const firstItem = dateFormat(firstItemForDate.createdAt);

        const isFirstDateGroupItem = firstItemForDate.id === feedItem.id;
        const dailyLabel = firstItem.formattedDate;
        const dateGroupLabel = isFirstDateGroupItem ? dailyLabel : "";

        const lastActiveItem = items[items.length - 1]?.id === feedItem.id;

        const lastActiveItemIdIsMine =
          lastActiveItem && feedItem?.accountId === myAccount?.id;

        const mySeenEventForItem = eventsMap
          .get(feedItem.id)
          ?.find(
            (event) =>
              event.name === "Saw Feed Item" &&
              event.accountId === myAccount?.id,
          );
        return {
          ...feedItem,
          id: feedItem.id,
          feedId: feedItem.feedId,
          contentId: feedItem.contentId,
          groupId: feedItem.groupId,
          deletedAt: feedItem.deletedAt,
          createdAt: feedItem.createdAt,
          accountId: feedItem.accountId,
          status: feedItem.status,
          mine: feedItem.accountId === myAccount?.id,
          unread: feedItem.unread,
          label: `${dailyLabel} @ ${formattedTime}`,
          key: feedItem.createdAt,
          isFirstDateGroupItem,
          dateGroupLabel,
          feedItemIndex: index++,
          lastActiveItem,
          lastActiveItemIdIsMine,
          shortDateStr,
          loadedContent: feedItem.loadedContent,
          url: `${window?.location?.href?.split("#")[0]}#${feedItem?.id}` || "",
          transcriptions: transcriptionMap.get(feedItem.contentId) || [],
          audioEncodings: audioEncodingMap.get(feedItem.contentId) || [],
          files: filesMap.get(feedItem.contentId) || [],
          displayArtifact: displayArtifactMap.get(feedItem.contentId)?.[0],
          links: linksMap.get(feedItem.contentId) || [],
          pam: pamMap.get(feedItem.contentId) || [],
          fromAppsync: feedItem.fromAppSync,
          hasRegisteredSeenEvent:
            feedItem.hasRegisteredSeenEvent || !!mySeenEventForItem,

          account: accountMap.get(feedItem.accountId),
          events: eventsMap.get(feedItem.id) || [],
        };
      }) || [];
    return ret;
  }, [
    items,
    myAccount,
    transcriptionMap,
    audioEncodingMap,
    eventsMap,
    accountMap,
    currentFeedId,
  ]);

  useEffect(() => {
    console.log("FeedContextProvider: MOUNTED");
    return () => {
      console.log("FeedContextProvider: UNMOUNTED");
    };
  }, []);

  const feedState: FeedState = {
    vListRef,
    fileUploadModal,
    status,
    templatesModal,
    itemTranscriptState,
    setItemTranscriptState,
    itemMessageDetailsState,
    scrollToBottomOfFeed,
    setItemMessageDetailsState,
    setFileUploadModal,
    setTemplatesModal,
    setStatus,
    // items,
    feedMap,
    currentFeed,
    // accountMap: accountsMap,
  };
  return (
    <FeedContext.Provider value={feedState}>{children}</FeedContext.Provider>
  );
};

export default FeedContextProvider;
