import theme from "@/theme/theme";
import CachedIcon from "@mui/icons-material/Cached";
import { Box, SxProps } from "@mui/material";
import React from "react";

/**
 * Generic Loading Container Full Width and Full Height with centered content
 * Allows ReactNode as children with optional sxProps and show loading icon
 * @param children
 * @param sx
 * @param showLoading
 * @constructor
 */
export default function LoadingContainer({
  children,
  sx,
  showLoading,
}: { children: React.ReactNode; sx?: SxProps; showLoading?: boolean }) {
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <Box
        sx={{
          background: theme.palette.secondary.dark,
          p: 2,
          display: "flex",
          alignItems: "center",
          borderRadius: "0.5rem",
          ...sx,
        }}
      >
        {showLoading && (
          <Box sx={{ display: "flex" }}>
            <CachedIcon
              sx={{
                color: theme.palette.secondary.light,
                mr: 1,
                animation: "spin 2s linear infinite",
                "@keyframes spin": {
                  "0%": {
                    transform: "rotate(360deg)",
                  },
                  "100%": {
                    transform: "rotate(0deg)",
                  },
                },
              }}
            />
          </Box>
        )}
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}
