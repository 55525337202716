import App from "@/App";
import { createBrowserRouter } from "react-router-dom";

import RedirectNotification from "@/components/RedirectNotification";
import RouteErrorFallback from "@/components/RouteErrorFallback";
import { ampli, client, config, flags } from "@/config";
import AuthSuccess from "@/routes/authSuccess";
import DirectAuth from "./directAuth";
import Onboarding from "./onboarding";
import Root from "./root";
import Workspace from "./workspace";
import LoadingWorkspace from "./workspace/LoadingWorkspace";
import MaintenanceMode from "./workspace/MaintenanceMode";
import DebugItemId from "./workspace/debugItem";
import FeedId from "./workspace/feedId";
import TemplateManagement from "./workspace/templateManagement";
import Workflows from "./workspace/workflows";
import WorkspaceId from "./workspace/workspaceId";
import WorkspaceUserManagement from "./workspace/workspaceUserManagement";

export const router = createBrowserRouter([
  {
    element: (
      <App config={config} flags={flags} client={client} ampli={ampli} />
    ),
    errorElement: <RouteErrorFallback />,
    children: [
      {
        path: "/",
        element: <Root />,
      },
      {
        path: "/workspaces",
        element: <Workspace />,
        children: [
          {
            path: "/workspaces/:workspaceId",
            element: <WorkspaceId />,
          },
          {
            path: "/workspaces/:workspaceId/loading-workspace",
            element: <LoadingWorkspace />,
          },
          {
            path: "/workspaces/:workspaceId/feeds/:feedId",
            element: <FeedId />,
          },
          {
            path: "/workspaces/:workspaceId/feeds/:feedId/items/:itemId/debug",
            element: <DebugItemId />,
          },
          {
            path: "/workspaces/:workspaceId/user-management",
            element: <WorkspaceUserManagement />,
          },
          {
            path: "/workspaces/:workspaceId/workflows",
            element: <Workflows />,
          },
          {
            path: "/workspaces/:workspaceId/template-management",
            element: <TemplateManagement />,
          },
        ],
      },
      {
        path: "/onboarding",
        element: <Onboarding />,
      },
      {
        path: "/authSuccess",
        element: <AuthSuccess />,
      },
      {
        path: "/notification-redirect",
        element: <RedirectNotification />,
      },
      {
        path: "/directAuth",
        element: <DirectAuth />,
      },
      {
        path: "/maintenance-mode",
        element: <MaintenanceMode />,
      },
    ],
  },
]);
