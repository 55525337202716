const Locator = {
  blankSlate: {
    requestDemo: {
      button: "Request a demo button",
    },
  },
  onBoarding: {
    modalTitle: "Welcome to Storyboard!",
    fullNameInput: "Full name*",
    submitButton: "Continue",
  },
  mainNav: {
    navBar: "Main navigation bar",
    workspaceNavToggle: "Workspace nav toggle",
    displayTitle: "Main navigation title",
    membersListToggle: "Members list toggle",
    feedbackButton: "Feedback button",
    languages: {
      dropdown: "Language dropdown",
      option: (language: string) => `Language option ${language}`,
    },
  },
  feed: {
    testId: (id: string) => `feed-${id}`,
    input: {
      tts: "Text to speech input",
      send: "Send text to speech button",
      recorder: {
        main: "Recording start button",
        cancel: "Recording cancel button",
        progress: "Recording progress",
      },
      fleetMessage: {
        main: "Fleet message button",
        modal: {
          name: "New Fleet Message",
          cancel: "Fleet message cancel button",
          continue: "Fleet message continue button",
          send: "Fleet message send button",
          tts: "Fleet message text to speech input",
          search: "Fleet message channel search input",
          selectAll: "Fleet message select all channels",
          testId: (channelId: string) => `fleet-message-channel-${channelId}`,
        },
      },
      templates: {
        main: "Templates button",
        modal: {
          name: "Use a template",
          listItem: "Template list item",
          selectedTitle: "Selected template title",
          selectedContent: "Selected template content",
          use: "Use template button",
          save: "Save template button",
        },
      },
    },
    items: {
      list: "Feed items list",
      item: "Feed item",
      actions: {
        main: "Message actions",
        menu: "Message actions menu",
        delete: "Delete message",
        copyLink: "Copy message link",
      },
      confirmMessageDeleteModal: {
        confirm: "Confirm message deletion",
        cancel: "Cancel message deletion",
      },
    },
    members: {
      addUsersButton: "Add users button",
      confirmRemove: "Confirm remove member",
      cancelRemove: "Cancel remove member",
      confirmPromote: "Confirm promote member",
      cancelPromote: "Cancel promote member",
      confirmDemote: "Confirm demote member",
      cancelDemote: "Cancel demote member",
      list: {
        itemId: (id: string) => `feed-member-${id}`,
        item: "Feed member item",
        isAdmin: "Feed member is admin",
        isMuted: "Feed member is muted",
        handsFreeStatus: "Feed member hands free status",
        context: {
          promote: "Promote member",
          demote: "Demote member",
          muteUnmute: "Mute or Unmute Member",
          remove: "Remove Member",
          sendDirectMessage: "Send Direct Message",
        },
      },
      addUsersModal: {
        cancelButton: "Cancel adding users to channel button",
        completeButton: "Complete add users to channel button",
        selectAll: "Select all toggle",
        addMemberButton: "Add member button",
        addMemberTestId: (id: string) => `add-member-${id}`,
      },
    },
  },
  workspaceNav: {
    workspaceTab: (tab: string) => `workspace-tab-${tab}`,
    optionsMenu: {
      button: "Workspace options menu button",
      members: "Workspace members option",
      edit: "Workspace edit option",
    },
    members: {
      addMember: "Add member button",
      inviteModal: {
        inviteTextField: "Invite user text field",
        inviteButton: "Invite user button",
        cancelButton: "Cancel invites",
        csvInfoButton: "CSV info button",
        downloadCsvTemplate: "Download CSV template button",
        uploadFileButton: "Upload file button",
        uploadedFile: (fileName: string) => `selected-file-${fileName}`,
        uploadError: "File upload error",
        invalidInvites: "Invalid invites table",
        invalidInvite: (credential: string) => `Invalid invite ${credential}`,
        fileUploadAccepted: "File upload accepted",
        sendEmailsCheckbox: "Send emails checkbox",
        inviteMoreButton: "Invite more button",
        doneButton: "Done button",
      },
      membersTab: "Members tab",
      pendingInvitesTab: "Pending invites tab",
      pendingInvites: {
        revokeButton: "Revoke invite button",
        revokeTestId: (id: string) => `revoke-invite-${id}`,
      },
      activeMembers: {
        revokeButton: "Revoke member button",
        revokeTestId: (id: string) => `revoke-invite-${id}`,
        roleSelect: "Member role select",
        roleSelectTestId: (id: string) => `role-select-${id}`,
      },
    },
    dropdown: {
      button: "Workspaces dropdown button",
      list: "Workspaces list",
      create: "Create New workspace button",
    },
    modal: {
      name: "Workspace name",
      cancelCreate: "Cancel workspace creation",
      cancelEdit: "Cancel workspace changes",
      continueCreate: "Continue workspace creation",
      continueEdit: "Continue workspace changes",
    },
    channels: {
      create: "Create New Channel",
      modal: {
        name: "Channel name",
        cancel: "Cancel channel modal",
        private: "Private channel",
        public: "Public channel",
        listenOnly: "Listen only channel",
        autoSubscribe: "Auto subscribe channel",
        silent: "Silent channel",
        create: "Create channel",
        save: "Save Channel",
      },
      find: {
        search: "Find a channel",
        clear: "Clear search",
      },
      filter: {
        options: "Filter options menu",
        showAll: "Show all channels",
        showMy: "Show my channels",
        showUnread: "Show unread messages",
        sortRecent: "Sort channels by most recent",
        sortAlpha: "Sort channels alphabetically",
      },
      list: {
        container: "Workspace channel list container",
        nonGroups: "Workspace List Items",
        testId: (id: string) => `workspace-channel-list-item-${id}`,
        item: "Workspace channel list item",
        subscribe: "Subscribe to notifications for this channel",
        leave: "Unsubscribe to notifications",
        unreadCount: "Unread count",
        unreadCountId: (id: string) => `unread-count-${id}`,
        markAsRead: "Mark channel as read",
        markAllAsRead: "Mark all channels as read",
        groups: {
          testId: (id: string) => `workspace-channel-group-${id}`,
          item: "Workspace channel group",
          markAllAsRead: "Mark all channels in group as read",
          subscribe: "Subscribe to notifications for this group",
          subscribeAll:
            "Subscribe to notifications for all channels in this group",
          unsubscribe: "Unsubscribe to notifications",
          unreadCountId: (id: string) => `unread-group-count-${id}`,
        },
      },
    },
    tools: {
      options: (key: string) => `tools-options-${key}`,
    },
    templates: {
      nav: "Go to Templates",
      listItem: "Template list item",
      createTemplate: "Create New Template",
      editTemplate: "Edit Template",
      deleteTemplate: "Delete Template",
      modal: {
        type: (type: string) => `${type}-template-modal`,
        name: "Template name",
        content: "Template content",
        cancel: "Cancel template creation",
        save: "Save template",
      },
      confirmDelete: "Confirm delete template modal",
    },
    confirmDeleteModal: {
      main: "Confirm discard changes modal",
      cancel: "Cancel deleting unsaved changes",
      confirm: "Confirm deleting unsaved changes",
    },
  },
};

export default Locator;
