import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UppyContext } from "@/models/UppyContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import {
  createScheduledWorkflowItem,
  createWorkflowItem,
} from "@/models/actions/clientActions";
import { CheckCircleOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Button, Stack } from "@mui/material";
import {
  getDate,
  getHours,
  getMinutes,
  isFuture,
  isPast,
  isValid,
  startOfDay,
} from "date-fns";
import { useContext, useEffect, useState } from "react";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import ScheduleMessageRecurringOptions from "./ScheduleMessageRecurringOptions";

export default function ScheduleMessageDateAndTime() {
  const [publishing, setPublishing] = useState<boolean>(false);
  const [published, setPublished] = useState<boolean>(false);
  const {
    doesRepeat,
    setDoesRepeat,
    goToPrevStep,
    displayName,
    scheduledText,
    selectedChannels,
    selectedDate,
    setSelectedDate,
    selectedDaysOfWeek,
    setSelectedDaysOfWeek,
    timezone,
    resetAll,
  } = useContext(SchedulingContext);
  const { addedFile, removeMetaData, uppyClient } = useContext(UppyContext);
  const [minDatetime, setMinDatetime] = useState<Date>(new Date(Date.now()));
  const [error, setError] = useState<boolean>(false);
  const { setScheduleMessageModalOpen, isSmUp } = useContext(UxContext);
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);

  const doesRepeatHandler = (doesRpt: boolean) => {
    setDoesRepeat(doesRpt);
  };

  const selectedDaysOfWeekHandler = (daysOfWeek: Array<number>) => {
    setSelectedDaysOfWeek(daysOfWeek);
  };

  const handleDateSelection = (date: Date) => {
    if (isValid(date)) {
      setSelectedDate(date);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (!doesRepeat && isPast(selectedDate)) {
      // if selected date is today, it cannot be earlier than the current datetime
      setError(true);
    } else {
      setError(false);
    }

    if (isFuture(selectedDate)) {
      setMinDatetime(startOfDay(selectedDate));
    } else {
      if (!doesRepeat) {
        setMinDatetime(selectedDate);
      } else {
        setMinDatetime(null);
      }
    }
  }, [doesRepeat, selectedDate]);

  const publishWorkflowItem = async () => {
    try {
      setPublishing(true);
      let contentId = "";
      if (addedFile) {
        contentId = addedFile?.meta?.contentId;
        // nullify the feedId to prevent uploads being sent right away
        // connect the contentId to the workflowItem
        removeMetaData("feedId");
        await uppyClient.upload();
      }

      const workflowItem = await createWorkflowItem(
        workspaceId,
        contentId,
        displayName,
        scheduledText,
      );
      let scheduledWorkflow: any = {
        workspaceId,
        workflowItemId: workflowItem?.id,
        feedIds: selectedChannels,
      };
      if (doesRepeat) {
        const mins = getMinutes(selectedDate);
        const hours = getHours(selectedDate);
        // const month = getMonth(selectedDate);
        const month = "*";
        let daysOfWeek = "?";
        let dayOfMonth = "?";

        if (selectedDaysOfWeek?.length > 0) {
          // figure out when we have the ability for multi day selection
          // daysOfWeek = selectedDaysOfWeek.join(",");
          daysOfWeek = selectedDaysOfWeek[0].toString();
          dayOfMonth = "?";
        } else {
          dayOfMonth = getDate(selectedDate).toString();
        }

        const scheduledCron = `${mins} ${hours} ${dayOfMonth} ${month} ${daysOfWeek} *`;
        scheduledWorkflow.scheduledCron = scheduledCron;
        scheduledWorkflow.timezone = timezone;
      } else {
        scheduledWorkflow.scheduledDate = selectedDate?.toISOString();
      }
      await createScheduledWorkflowItem(scheduledWorkflow);
      setPublished(true);
      setPublishing(false);
      setTimeout(() => {
        setPublished(false);
      }, 3000);
    } catch (e) {
      console.log("ERROR", e);
    } finally {
      resetAll();
      setScheduleMessageModalOpen(false);
    }
  };

  return (
    <>
      <Stack sx={{ width: "100%", gap: 2, position: "relative" }}>
        <DatePicker
          label="Publish Date*"
          selectedDate={selectedDate}
          dateSelectionHandler={handleDateSelection}
          disabled={doesRepeat || publishing}
        />
        <TimePicker
          label="Publish Time*"
          selectedTime={selectedDate}
          timeSelectionHandler={handleDateSelection}
          disabled={!selectedDate || publishing}
          minDate={minDatetime}
        />
        <Stack sx={{ position: "relative" }}>
          <ScheduleMessageRecurringOptions
            doesRepeat={doesRepeat}
            doesRepeatHandler={doesRepeatHandler}
            disabled={publishing}
            selectedDate={selectedDate}
            selectedDaysOfWeek={selectedDaysOfWeek}
            selectedDaysOfWeekHandler={selectedDaysOfWeekHandler}
          />
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={{ order: { xs: 1, sm: 0 } }}
            onClick={goToPrevStep}
            disabled={publishing}
          >
            Back
          </Button>
          <LoadingButton
            loading={publishing}
            disabled={error || (doesRepeat && selectedDaysOfWeek.length === 0)}
            variant="contained"
            color="primary"
            sx={{ order: { xs: 0, sm: 1 } }}
            onClick={publishWorkflowItem}
          >
            Publish
          </LoadingButton>
        </Stack>
      </Stack>
      {published ? (
        <Alert
          variant="filled"
          severity="success"
          sx={{
            position: "fixed",
            width: "100%",
            left: 0,
            top: isSmUp ? `calc(100% + 24px)` : 0,
            borderRadius: isSmUp ? "12px" : 0,
            "& .MuiAlert-icon": {
              padding: 0,
            },
          }}
          iconMapping={{
            success: <CheckCircleOutlined fontSize="inherit" sx={{ p: 0 }} />,
          }}
        >
          New message scheduled
        </Alert>
      ) : null}
    </>
  );
}
