import { client } from "@/config";
import {
  deleteWsDraft,
  upsertDirectWsInvitation,
  upsertWorkspaceMembership,
  upsertWsBroadcastAction,
  upsertWsBroadcastRecipient,
  upsertWsDraft,
  upsertWsFeedPreference,
  upsertWsFeedPreferences,
  upsertWsItem,
  upsertWsLink,
  upsertWsScheduleTrigger,
  upsertWsTranscription,
} from "@/data/pg/updates";
import { db } from "@/db/db";
import { feed, item } from "@/db/schema";
import cuid from "cuid";
import { eq } from "drizzle-orm";
import Client from "web-client";
import {
  CreateWorkspaceScheduledBroadcastResponse,
  WorkspaceInvitation,
  WorkspaceRole,
  WsFeedPreference,
  WsWorkflowItem,
} from "web-client/api/data-contracts";

export async function deleteItem(itemId: string) {
  const feedItem = await db.query.item
    .findFirst({ where: eq(item.id, itemId) })
    .execute();
  const feedRecord = await db.query.feed
    .findFirst({ where: eq(feed.id, feedItem?.feedId) })
    .execute();
  if (!feedRecord || !feedRecord.workspaceId || !itemId) return;

  const res = await client.deleteWorkspaceFeedItem(
    feedRecord.workspaceId,
    feedRecord.id,
    itemId,
  );

  if (res?.item) {
    upsertWsItem(res.item);
  }
}

export async function sendWorkspaceInvites(
  workspaceId: string,
  emails: string[] = [],
  phoneNumbers: string[] = [],
  sendEmail = false,
  invites: WorkspaceInvitation[] = [],
) {
  const res = await client.createWorkspaceInvitations(
    workspaceId,
    emails,
    phoneNumbers,
    sendEmail,
    invites,
  );
  const invitations = res?.invitations || [];
  const workspaceMemberships = res?.workspaceMemberships || [];
  for (const i of invitations) {
    upsertDirectWsInvitation(i);
  }
  for (const m of workspaceMemberships) {
    upsertWorkspaceMembership(m);
  }
  return {
    invitations,
    workspaceMemberships,
  };
}

export async function updateMemberRole(
  workspaceId: string,
  workspaceMembershipIds: string[],
  role: WorkspaceRole,
) {
  const resp = await client.updateWorkspaceMember(
    workspaceId,
    workspaceMembershipIds,
    role,
  );
  for (const workspaceMembership of resp?.workspaceMemberships || []) {
    upsertWorkspaceMembership(workspaceMembership);
  }
}

export async function removeMember(
  workspaceId: string,
  workspaceMembershipIds: string[],
) {
  const resp = await client.removeWorkspaceMember(
    workspaceId,
    workspaceMembershipIds,
  );
  for (const workspaceMembership of resp?.workspaceMemberships || []) {
    upsertWorkspaceMembership(workspaceMembership);
  }
}

export async function subscribeToFeed({
  workspaceId,
  feedId,
  workspaceMembershipId,
}: {
  workspaceId: string;
  feedId: string;
  workspaceMembershipId: string;
}): Promise<WsFeedPreference> {
  const { feedPreference } = await client.subscribeToFeed({
    workspaceId,
    feedId,
    workspaceMembershipId,
  });
  await upsertWsFeedPreference(feedPreference);
  return feedPreference;
}

export async function unsubscribeFromFeed({
  workspaceId,
  feedId,
  workspaceMembershipId,
}: {
  workspaceId: string;
  feedId: string;
  workspaceMembershipId: string;
}): Promise<WsFeedPreference> {
  const { feedPreference } = await client.unsubscribeFromFeed({
    workspaceId,
    feedId,
    workspaceMembershipId,
  });
  await upsertWsFeedPreference(feedPreference);
  return feedPreference;
}

export async function subscribeToFeedGroup({
  workspaceId,
  feedGroupId,
  workspaceMembershipId,
}: {
  workspaceId: string;
  feedGroupId: string;
  workspaceMembershipId: string;
}) {
  const { feedPreferences } = await client.subscribeToFeedGroup({
    workspaceId,
    feedGroupId,
    workspaceMembershipId,
  });
  await upsertWsFeedPreferences(feedPreferences);
  return feedPreferences;
}

export async function unsubscribeFromFeedGroup({
  workspaceId,
  feedGroupId,
  workspaceMembershipId,
}: {
  workspaceId: string;
  feedGroupId: string;
  workspaceMembershipId: string;
}) {
  const { feedPreferences } = await client.unsubscribeFromFeedGroup({
    workspaceId,
    feedGroupId,
    workspaceMembershipId,
  });
  await upsertWsFeedPreferences(feedPreferences);
  return feedPreferences;
}

export async function createWorkflowItem(
  workspaceId: string,
  contentId?: string,
  displayName?: string,
  text?: string,
) {
  let workflowItem: WsWorkflowItem;
  if (text) {
    workflowItem = await client.createWorkspaceWorkflowItem({
      workspaceId: workspaceId,
      displayName: displayName,
      inputText: text,
    });
  } else {
    workflowItem = await client.createWorkspaceWorkflowItem({
      workspaceId: workspaceId,
      contentId: contentId,
      displayName: displayName,
    });
  }
  if (!workflowItem) throw new Error("WorkflowItem not created");
  upsertWsDraft(workflowItem);
  return workflowItem;
}

export async function deleteWorkflowItem(
  workspaceId: string,
  workflowItemId: string,
) {
  await client.deleteWorkspaceWorkflowItem(workspaceId, workflowItemId);
  await deleteWsDraft(workflowItemId);
}

export async function createScheduledWorkflowItem({
  workspaceId,
  workflowItemId,
  feedIds,
  scheduledDate,
  scheduledCron,
  timezone,
}: {
  workspaceId: string;
  workflowItemId: string;
  feedIds: Array<string>;
  scheduledDate?: string;
  scheduledCron?: string;
  timezone?: string;
}): Promise<CreateWorkspaceScheduledBroadcastResponse> {
  let props: Parameters<typeof client.createScheduledBroadcast>[0] = {
    workspaceId: workspaceId,
    feedIds,
    workflowItemId: workflowItemId,
  };

  if (scheduledDate) {
    props = { ...props, date: scheduledDate };
  }

  if (scheduledCron) {
    props = { ...props, cron: scheduledCron, timezone: timezone };
  }

  const scheduledBroadcast = await client.createScheduledBroadcast(props);

  if (!scheduledBroadcast) throw new Error("scheduledBroadcast not created");

  console.log("created broadcast", scheduledBroadcast);
  if (scheduledBroadcast) {
    if (scheduledBroadcast?.scheduleTrigger) {
      upsertWsScheduleTrigger(scheduledBroadcast?.scheduleTrigger);
    }

    if (scheduledBroadcast?.broadcastAction) {
      upsertWsBroadcastAction(scheduledBroadcast?.broadcastAction);
    }

    if (scheduledBroadcast?.broadcastRecipients) {
      for (const broadcastRecipient of scheduledBroadcast?.broadcastRecipients ||
        []) {
        upsertWsBroadcastRecipient(broadcastRecipient);
      }
    }
  }

  return scheduledBroadcast;
}

export async function updateScheduledWorkflowItem({
  workspaceId,
  scheduleId,
  feedIds,
  scheduledDate,
  scheduledCron,
  timezone,
}: {
  workspaceId: string;
  scheduleId: string;
  feedIds: Array<string>;
  scheduledDate?: string;
  scheduledCron?: string;
  timezone?: string;
}) {
  let props: {
    workspaceId: string;
    feedIds: string[];
    scheduleId: string;
    date?: string;
    cron?: string;
    timezone?: string;
  } = { workspaceId, feedIds, scheduleId };
  if (scheduledDate) props.date = scheduledDate;
  if (scheduledCron) {
    props.cron = scheduledCron;
    props.timezone = timezone;
  }

  const scheduledBroadcast = await client.updateScheduledBroadcast(props);
  if (!scheduledBroadcast) throw new Error("scheduledBroadcast not updated");

  if (scheduledBroadcast?.scheduleTrigger) {
    upsertWsScheduleTrigger(scheduledBroadcast?.scheduleTrigger);
  }

  if (scheduledBroadcast?.broadcastAction) {
    upsertWsBroadcastAction(scheduledBroadcast?.broadcastAction);
  }

  if (scheduledBroadcast?.broadcastRecipients) {
    for (const broadcastRecipient of scheduledBroadcast.broadcastRecipients) {
      upsertWsBroadcastRecipient(broadcastRecipient);
    }
  }
}

export async function publishToWorkspaceFeed({
  workspaceId,
  feedId,
  itemId,
  contentId,
  groupId,
  text,
  preferredLanguage,
  isSilent,
}: {
  workspaceId: string;
  feedId: string;
  itemId?: string;
  contentId: string;
  groupId?: string;
  text?: string;
  preferredLanguage?: string;
  isSilent?: boolean;
}) {
  const tempItem = {
    id: itemId || cuid(),
    feedId,
    contentId,
    groupId,
    isSilent,
  };
  client
    .publishTTS({
      workspaceId,
      feedId,
      itemId: tempItem.id,
      contentId,
      groupId,
      text,
      preferredLanguage,
      isSilent,
    })
    .then((res) => {
      const { item, links, transcriptions } = res;
      upsertWsItem(item);
      for (const link of links || []) {
        upsertWsLink(link);
      }
      for (const transcription of transcriptions || []) {
        upsertWsTranscription(transcription);
      }
    });
  return tempItem;
}
